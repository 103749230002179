var CONSTANTS = {
  OUTBOUND_BLOCKED_USERS: [
    "ASyctsxxmU",
    "qmnpEPdXW8",
    "N8aW0GL7wR",
    "3pUu1RL17G",
  ],
  NAV_ITEMS: [
    {
      label: "Manager",
      id: "performance_tab",
      link: "/dashboard",
      icon: "dashboard",
      requiredRole: [1, 3, 7, 8],
      activeRoutes: ["/dashboard", "/dashboard/leads"],
      subMenu: [
        {
          label: "Task Performance",
          id: "task_performance_tab",
          link: "/dashboard",
          requiredRole: [1, 3, 4, 7, 8, 10],
          activeRoutes: ["/dashboard"],
        },
        {
          label: "Leads Performance",
          id: "leads_performance_tab",
          link: "/dashboard/tab/leads",
          requiredRole: [1],
          activeRoutes: ["/dashboard/tab/leads"],
        },
      ],
    },
    // {
    //     label: "Partners",
    //     link: "/giggers",
    //     id: "partners_tab",
    //     icon: "person_search",
    //     requiredRole: [1, 7, 3, 4, 9, 10],
    //     activeRoutes: ["/giggers"]
    // },
    // {
    //     label: "New Users",
    //     id: "new_user",
    //     link: "/recruitment/new-user",
    //     icon: "person",
    //     requiredRole: [1, 3, 4, 9, 10],
    //     activeRoutes: ["/recruitment/new-user"]
    // },
    // {
    //     label: "Training completed",
    //     id: "active_tab",
    //     link: "/to_be_activate",
    //     icon: "on_device_training",
    //     requiredRole: [1, 3, 4, 9 ,10],
    //     activeRoutes: ["/to_be_activate"]
    // },
    {
      label: "Team Leaders",
      id: "portfolio_tab",
      link: "/portfolio",
      icon: "groups",
      requiredRole: [1, 3, 10],
      activeRoutes: ["/portfolio"],
    },
    {
      label: "Dormant Users",
      id: "dormant_users",
      link: "/dormant_users",
      icon: "model_training",
      requiredRole: [1, 3, 4, 9, 10],
      activeRoutes: ["/dormant_users"],
    },
    {
      label: "Recruitment",
      id: "recruitment_tab",
      link: "/recruitment",
      icon: "done_all",
      requiredRole: [1, 3, 4, 5, 7, 10],
      activeRoutes: ["/recruitment"],
    },
    {
      label: "Projects",
      id: "project_tab",
      link: "/project/overview",
      icon: "work",
      requiredRole: [1, 3],
      activeRoutes: ["/project/overview", "/project/create"],
    },
    {
      label: "Payouts",
      id: "payout_tab",
      link: "/payout",
      icon: "attach_money",
      requiredRole: [1],
      activeRoutes: ["/payout"],
    },
    {
      label: "Invoice",
      id: "invoice_tab",
      link: "/invoice",
      icon: "receipt_long",
      requiredRole: [1],
      activeRoutes: ["/invoice"],
    },
    {
      label: "Call Logs",
      id: "call_logs_tab",
      link: "/call/logs",
      icon: "call",
      requiredRole: [1, 3],
      activeRoutes: ["/call/logs"],
    },
    {
      label: "Webinar",
      id: "webinar_tab",
      link: "/webinar",
      icon: "event",
      requiredRole: [1, 3, 4, 9],
      activeRoutes: ["/webinar"],
    },
    {
      label: "Customer Leads",
      id: "leads",
      link: "/leads",
      icon: "supervised_user_circle",
      requiredRole: [1, 3, 5, 6, 7, 8, 10],
      activeRoutes: ["/leads"],
    },
    {
      label: "Manage Access",
      id: "manage_access",
      link: "/access/manage",
      icon: "manage_accounts",
      requiredRole: [1],
      activeRoutes: ["/access/manage"],
    },
    {
      label: "Logout",
      id: "logout",
      link: "/log_out",
      icon: "settings_power",
      requiredRole: [1, 3, 4, 5, 6, 7, 8, 9, 10],
      activeRoutes: null,
    },
  ],
  APPLICANTS_STATES: {
    LEADS: {
      name: "Leads",
      nextStates: {},
    },
    // APPLIED : {
    //     name : "Applied",
    //     nextStates : { SHORTLISTED : "Select", REJECTED : "Reject"}
    // },
    // INTERVIEWED : {
    //     name : "Interviewed",
    //     nextStates : {SHORTLISTED : "Select", REJECTED : "Reject"}
    // },
    SHORTLISTED: {
      name: "Interested",
      nextStates: {
        TERMINATED: "Terminate",
        REJECTED: "Reject",
        QUIT_JOB: "Quit Project",
      },
    },
    // REJECTED : {
    //     name : "Rejected",
    //     nextStates : {TERMINATED : "Terminate", SHORTLISTED : "Select"}
    // },
    // OFFER_ACCEPTED : {
    //     name : "Offer Accepted",
    //     nextStates : {TERMINATED : "Terminate", INTERVIEWED: "Revert to Interviewed", SHORTLISTED: "Revert to Shortlisted", REJECTED: "Rejected",}
    // },
    IN_TRAINING: {
      name: "Training/Webinar",
      nextStates: { TERMINATED: "Terminate", QUIT_JOB: "Quit Project" },
    },
    // TRAINING_COMPLETED : {
    //     name : "Training Completed",
    //     nextStates : {TERMINATED : "Terminate"}
    // },
    IN_TASK: {
      name: "In Task",
      nextStates: { TERMINATED: "Terminate", QUIT_JOB: "Quit Project" },
    },
    IN_TASK_1: {
      name: "Task - 1",
      nextStates: { TERMINATED: "Terminate", QUIT_JOB: "Quit Project" },
    },
    IN_TASK_5: {
      name: "Task - 5",
      nextStates: { TERMINATED: "Terminate", QUIT_JOB: "Quit Project" },
    },
    IN_TASK_10: {
      name: "Task - 10",
      nextStates: { TERMINATED: "Terminate", QUIT_JOB: "Quit Project" },
    },
    TERMINATED: {
      name: "Terminated",
      nextStates: {
        SHORTLISTED: "Revert to Interested",
        IN_TASK: "Revert to In Task",
      },
    },
    ON_HOLD: {
      name: "On-Hold",
      nextStates: {
       
      },
    },
    QUIT_JOB: {
      name: "Quit",
      nextStates: {
        SHORTLISTED: "Revert to Interested",
        IN_TASK: "Revert to In Task",
      },
    },
    INACTIVE: {
      name: "Inactive",
      nextStates: { TERMINATED: "Terminate", QUIT_JOB: "Quit Project" },
    },
  },
  APPLICATION_STATUS: {
    LEADS: 0,
    APPLIED: 1,
    INTERVIEWED: 4,
    SHORTLISTED: 2,
    // REJECTED: 3,
    OFFER_ACCEPTED: 11,
    IN_TRAINING: 5,
    TRAINING_COMPLETED: 6,
    IN_TASK: 7,
    TERMINATED: 8,
    QUIT_JOB: 9,
  },
  APPLICATION_STATUS_TEXT: {
    0: "Lead",
    1: "Interested",
    4: "Interested",
    2: "Interested",
    // 3: "Rejected",
    11: "Offer Accepted",
    5: "In Training",
    6: "Training Completed",
    7: "In Task",
    8: "Terminated",
    9: "Quit",
  },
  APPLICATION_ORIGIN: {
    1: "Facebook",
    2: "LinkedIn",
    3: "Indeed",
    4: "Apna",
    5: "Quikr",
    6: "OlX",
    7: "Other Job Portals",
    8: "SMS Campaign",
    9: "Website",
    10: "Verbal Referral",
    99: "Other",
  },
  VERIFICATION_STATUS: { PENDING: 0, VERIFIED: 1, REJECTED: 2 },
  ARCHIVE_STATUS: { ON: 0, HOLD: 1, OFF: 2 },
  ARCHIVE_STATUS_TEXT: { 0: "ON", 1: "HOLD", 2: "OFF" },
  AUDIT_STATUS: {
    0: "",
    1: "OK",
    2: "Required",
    3: "Submitted",
    4: "Verified",
    5: "Closed",
  },
  EXTRA_DETAILS_STATUS: {
    1: "ID Request Initiated",
    2: "IDs Requested",
    3: "IDs Added",
  },
  JOB_QUESTION_STATES: {
    APPLIED: "Apply",
    INTERVIEWED: "Interview",
    SHORTLISTED: "Shortlist",
    REJECTED: "Reject",
    OFFER_ACCEPTED: "Offer Accept",
    IN_TRAINING: "Start Training",
    TRAINING_COMPLETED: "Training Complete",
    IN_TASK: "In Task",
    TERMINATE: "Terminate",
    QUIT_JOB: "Quit Project",
  },
  NOTIFICATION_ORIGIN: [
    { id: "HOME", title: "Home Screen" },
    { id: "JOB_TASK", title: "Project Details" },
    { id: "WALLET_TRANSACTION", title: "Wallet Screen" },
    { id: "HOME_LEADERBOARD", title: "Home Leaderboard" },
    { id: "TASK_LEADERBOARD", title: "Task Leaderboard" },
    { id: "REFERRAL", title: "Referral" },
    { id: "TRAINING", title: "Training Screen" },
    { id: "MY_TEAM", title: "My Team Screen" },
  ],
  PROFILE: {
    EMPLOYEMENT_STATUS: {
      1: "Student",
      2: "working",
      3: "Looking for a job",
      4: "Part  time working",
    },
    EDUCATION_STATUS: {
      1: "Xth",
      2: "XIIth",
      3: "Graduation",
      4: "Post Graduation",
    },
    AVAILABILITY: {
      1: "Full-time",
      2: "Part-time",
      3: "Not Interested",
    },
    WORKING_PATTERN: {
      1: "Weekdays",
      2: "Weekends",
      3: "Unclear",
    },
    WORK_EXPERIENCE: {
      1: "Sales",
      2: "Operations",
      3: "Warehouse",
      4: "Insurance",
      5: "Retail",
      6: "Payments Apps",
      7: "Delivery",
      8: "Fresher",
      9: "Security",
      10: "Teacher",
      11: "Healthcare provider",
      12: "Restaurant worker",
      13: "Other",
    },
  },
  JOB_REQUIREMENTS: [
    {
      eligibility_type: "ASSET",
      eligibility_subtype: "VEHICLE",
      comparison_with: "=",
      eligibility_value: "Motor Bike",
    },
    {
      eligibility_type: "TRAINING",
      eligibility_subtype: "",
      comparison_with: "=",
      eligibility_value: "Training",
    },
    {
      eligibility_type: "ASSET",
      eligibility_subtype: "DEVICE",
      comparison_with: "=",
      eligibility_value: "Smart Phone",
    },
    {
      eligibility_type: "ASSET",
      eligibility_subtype: "DEVICE",
      comparison_with: "=",
      eligibility_value: "Laptop",
    },
    {
      eligibility_type: "DOCUMENT",
      eligibility_subtype: "",
      comparison_with: "=",
      eligibility_value: "License",
    },
  ],
  JOB_LOCATIONS: [],
  CALL_COMMENTS: {
    Callback: { type: "DATE_TIME", label: "Callback Time" },
    Interested: {
      type: "ENUM",
      label: "Interested Level",
      values: ["Not Interested", "Interested", "Super Interested"],
      defaultValue: "Interested",
    },
    // "Webinar Interested": {
    //     type: "ENUM",
    //     label: "Interested Level",
    //     values: ["Not Interested", "Interested", "Super Interested"],
    //     defaultValue: "Interested"
    // },
    "Abusive Call": { defaultValue: "Abusive" },
    Other: { defaultValue: "Other" },
  },
  REQUIREMENTS: [
    {
      label: "Email ID",
      eligibility_for: 0,
      eligibility_type: "PROFILE",
      eligibility_subtype: "EMAIL",
      comparison_with: "==",
      eligibility_value: "EMAIL",
      inputValue: false,
    },
    {
      label: "Date of Birth",
      eligibility_for: 0,
      eligibility_type: "PROFILE",
      eligibility_subtype: "DOB",
      comparison_with: "==",
      eligibility_value: "DOB",
      inputValue: false,
    },
    {
      label: "Profile Picture",
      eligibility_for: 0,
      eligibility_type: "PROFILE",
      eligibility_subtype: "PROFILE_PICTURE",
      comparison_with: "==",
      eligibility_value: "PROFILE_PICTURE",
      inputValue: false,
    },
    {
      label: "Signature",
      eligibility_for: 0,
      eligibility_type: "DOCUMENT",
      eligibility_subtype: "SIGNATURE",
      comparison_with: "==",
      eligibility_value: "SIGNATURE",
      inputValue: false,
    },
    {
      label: "Aadhaar",
      eligibility_for: 0,
      eligibility_type: "DOCUMENT",
      eligibility_subtype: "AADHAAR",
      comparison_with: "==",
      eligibility_value: "AADHAAR",
      inputValue: false,
    },
    {
      label: "Aadhaar (with Photo)",
      eligibility_for: 0,
      eligibility_type: "DOCUMENT",
      eligibility_subtype: "AADHAAR_PHOTO",
      comparison_with: "==",
      eligibility_value: "AADHAAR",
      inputValue: false,
    },
    {
      label: "Aadhaar - Exclusively for Projects(with Photo)",
      eligibility_for: 0,
      eligibility_type: "DOCUMENT",
      eligibility_subtype: "AADHAAR_PHOTO",
      comparison_with: "==",
      eligibility_value: "AADHAAR_JOB",
      inputValue: false,
    },
    {
      label: "PAN",
      eligibility_for: 0,
      eligibility_type: "DOCUMENT",
      eligibility_subtype: "PAN",
      comparison_with: "==",
      eligibility_value: "PAN",
      inputValue: false,
    },
    {
      label: "PAN (with Photo)",
      eligibility_for: 0,
      eligibility_type: "DOCUMENT",
      eligibility_subtype: "PAN_PHOTO",
      comparison_with: "==",
      eligibility_value: "PAN",
      inputValue: false,
    }, 
    {
      label: "PAN - Exclusively for Projects(with Photo)",
      eligibility_for: 0,
      eligibility_type: "DOCUMENT",
      eligibility_subtype: "PAN_PHOTO",
      comparison_with: "==",
      eligibility_value: "PAN_JOB",
      inputValue: false,
    },
    {
      label: "Bank Passbook Photo",
      eligibility_for: 0,
      eligibility_type: "DOCUMENT",
      eligibility_subtype: "BANK_PASSBOOK",
      comparison_with: "==",
      eligibility_value: "BANK_PASSBOOK",
      inputValue: false,
    },
    {
      label: "Customer Age (>=)",
      eligibility_for: 1,
      eligibility_type: "PROFILE",
      eligibility_subtype: "age",
      comparison_with: ">=",
      inputValue: 18,
      inputFormat: "number",
    },
    {
      label: "Customer Monthly Earning (>=)",
      eligibility_for: 1,
      eligibility_type: "PROFILE",
      eligibility_subtype: "earning",
      comparison_with: ">=",
      inputValue: 10000,
      inputFormat: "number",
    },
    {
      label: "Customer CIBIL Score (>=)",
      eligibility_for: 1,
      eligibility_type: "PROFILE",
      eligibility_subtype: "cibil_score",
      comparison_with: ">=",
      inputValue: 650,
      inputFormat: "number",
    },
    {
      label: "Customer Occupation",
      eligibility_for: 1,
      eligibility_type: "PROFILE",
      eligibility_subtype: "occupation",
      comparison_with: "==",
      inputValue: ["Student", "Not Working", "Working", "Business", "Retired"],
    },
    {
      label: "Customer must have Bank Account",
      eligibility_for: 1,
      eligibility_type: "PROFILE",
      eligibility_subtype: "is_bank_account",
      comparison_with: "==",
      inputValue: false,
      eligibility_value: "1",
    },
  ],
  TICKET_TYPE_TEXT: {
    0: "Others",
    10: "How to Start on PickMyWork",
    20: "Project's Training",
    30: "Pending Tasks",
    40: "Rejected Tasks",
    50: "Payment Query",
    60: "Offers (Mobile Recharge)",
  },
  TICKET_TYPE_ICON: {
    10: "school",
    20: "model_training",
    50: "account_balance_wallet",
    60: "book_online",
  },
  TICKET_STATUS_TEXT: {
    0: "Open",
    10: "Pending - Partner",
    20: "Pending - Agent",
    30: "Resolved",
    40: "Closed",
  },
  TICKET_TEAMS: {
    0: "General Query",
    10: "Training Team - B2B",
    20: "Training Team - B2C",
    30: "Task Verification - B2B",
    40: "Task Verification - B2C",
    50: "Audit Team",
    60: "Payments Team",
    70: "Inbound Support Team",
    80: "Backup Support Team",
  },
  JOB_TAGS: {
    no_joining_fee: "Free Joining",
    no_annual_fee: "Lifetime Free",
    joining_rewards: "Welcome Gift",
    recommended: "Recommended",
    popular: "Popular",
    dining: "Dining",
    shopping: "Shopping",
    fuel: "Fuel",
    travel: "Traveling",
    lounge_access: "Airport Lounge",
    movie: "Movie Tickets",
    high_rewards: "High Rewards",
  },
  badgeConfig: [
    {
      id: 0,
      name: "Bronze",
      baseColor: "#5C4B2F",
      secondaryColor: "#87714E",
      earningRange: [0, 5000],
      earningBonus: 0,
      iconSvgName: "Bronze.svg"
    },
    {
      id: 1,
      name: "Silver",
      baseColor: "#696969",
      secondaryColor: "#868686",
      earningRange: [5000, 15000],
      earningBonus: 5,
      iconSvgName: "Silver.svg"
    },
    {
      id: 2,
      name: "Gold",
      baseColor: "#9C6C00",
      secondaryColor: "#F8AE00",
      earningRange: [15000, 40000],
      earningBonus: 10,
      iconSvgName: "Gold.svg"
    },
    {
      id: 3,
      name: "Diamond",
      baseColor: "#4B0000",
      secondaryColor: "#E40001",
      earningRange: [40000],
      earningBonus: 15,
      iconSvgName: "Diamond.svg"
    }
  ]
};

export default CONSTANTS;


import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col , Table  } from "reactstrap/lib";
import Input from "reactstrap/lib/Input";
import { Button } from 'reactstrap';
import InputFeild from "../../global/form/input"
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { Chip } from "@material-ui/core";
import SelectList from "../../global/form/select";
import CONSTANTS from "../../../constants";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

class SendNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobList: [],
            sendNotificationOpts: this.props.redirectOpts || {},
            isRemoveAlreadySent: false,
            image_file: null
        }
    }

    componentDidMount(){
        this.fetchJob();
    }

    fetchJob = async () => {
        // store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.Jobs.fetch();
            await this.setState({ jobList : apiData.data.data})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        // store.dispatch(setLoader(false))
    }

    fetchJobTaskForNotification = async (job_id) => {
        store.dispatch(setLoader(true));
        let { isAdd } = this.state;
        try{
            let apiData = await ApiEndpoints.JobTask.fetch({job_id});
            isAdd.taskData = apiData.data.data;
            await this.setState({ isAdd });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchJobTaskForNotification = async (job_id) => {
        store.dispatch(setLoader(true));
        let { sendNotificationOpts } = this.state;
        try{
            let apiData = await ApiEndpoints.JobTask.fetch({job_id});
            sendNotificationOpts.taskData = apiData.data.data;
            await this.setState({ sendNotificationOpts });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    handleCampaignSubmit = async (e) => {
        e.preventDefault();
        let { sendNotificationOpts, image_file } = this.state;
        let {campaign_id} = this.props;
       
        store.dispatch(setLoader(true))
        try{
            let body = {
                campaign_id: campaign_id,
                type : 0,
                notification_data : {
                    title : e.target.title.value,
                    message : e.target.message.value,
                    redirection_screen: sendNotificationOpts.originType,
                },
                ...(e.target.scheduled_on ? {scheduled_on: moment(e.target.scheduled_on.value).utc() } : {})
            }

            if(image_file){
                let fileName = "campaigns/notification/"+ new Date().getTime() + "_" + image_file.name;
                let fileResp = await ApiEndpoints.Files.filePublicPutUrl({file_name: fileName});
                let file_url = fileResp.data.data.url;
                if (!file_url) throw new Error("Unable to upload file. Please try again later.");
                var config = { headers: { "Content-Type": image_file.type }}
                await ApiEndpoints.Files.uploadFileToS3(file_url, image_file, config);

                body.notification_data.imageUrl = fileResp.data.data.fileUrl
            }

            if(["JOB_TASK", "TASK_LEADERBOARD"].indexOf(sendNotificationOpts.originType) !== -1){
                if(!sendNotificationOpts.job_id) throw new Error("Select Project for redirection");
                body.notification_data.redirection_screen = "JOB";
                body.notification_data.redirection_entity_type = "JOB";
                body.notification_data.redirection_entity_id = sendNotificationOpts.job_id;
            }

        let resp = await ApiEndpoints.CampaignFilter.scheduleCampagin(body)
        store.dispatch(setSuccess({show: true, message: resp.data.message}))
        this.props.onClose();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

   
    handleNotificationSubmit = async (e) => {
        if (this.props.campaignNotification) {
            return this.handleCampaignSubmit(e)
        }
        e.preventDefault();
        e.persist();
        let { sendNotificationOpts } = this.state;
        var userRole = store.getState().user.UserRole
        var isAdmin = !!userRole.filter(role => role.role_id === 1).length; 
        var msgBody = {
            title : e.target.title.value,
            message : e.target.message.value,
            notification_type : e.target.notification_type.value,
            template_id: this.state.smsTemplateId,
            redirection_screen: sendNotificationOpts.originType,
            ...this.props.prepareFilter()
        }
        if(e.target.isRemoveAlreadySent && e.target.isRemoveAlreadySent.value) msgBody.isRemoveAlreadySent = parseInt(e.target.isRemoveAlreadySent.value);
        store.dispatch(setLoader(true))
        try{
            if(["JOB_TASK", "TASK_LEADERBOARD"].indexOf(sendNotificationOpts.originType) !== -1){
                if(!sendNotificationOpts.job_id) throw new Error("Select Project for redirection");
                msgBody.redirection_screen = "JOB";
                msgBody.redirection_entity_type = "JOB";
                msgBody.redirection_entity_id = sendNotificationOpts.job_id;
            }

            // if(["JOB_TASK", "TASK_LEADERBOARD"].indexOf(sendNotificationOpts.originType) !== -1){
            //     // if(!sendNotificationOpts.job_task_id) throw new Error("Select Project Task for redirection");
            //     msgBody.redirection_sub_entity_type = "JOB_TAS";
            //     msgBody.redirection_sub_entity_id = sendNotificationOpts.job_task_id;
            // }
            // console.log(msgBody)
            // if(e.target.sms.checked && !this.state.smsTemplateId) throw new Error("Please select SMS Template");
            let notificationResp = await ApiEndpoints.User.sendNotificationByFilter(msgBody);
            if(isAdmin){
            var body = {
                notification_id : notificationResp.data.data.notification_id,
                notification_type : "NOTIFICATION",
                is_publish: 1
              }
              await ApiEndpoints.Notification.updateNotification(body); 
              store.dispatch(setSuccess({show: true, message: "Notification Sent Successfully"}));
            }
            else {
                store.dispatch(setSuccess({show: true, message: "Notification Request Added, Ask admin to approve"}));
            }
            this.props.onClose();
            e.target.reset();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
        
    }






    render() {
        let { jobList, sendNotificationOpts ,isRemoveAlreadySent} = this.state;
        let { userCount, campaignNotification, scheduleNotificationDetails} = this.props;
        return (
                <form onSubmit={this.handleNotificationSubmit}>
                    {/* <div>
                        <label className="radio-inline"><input type="radio" className="mr-3" name="notification_type" value="NOTIFICATION" checked />General Notification</label>
                        <label className="radio-inline ml-3"><input className="mr-3" type="radio" name="notification_type" value="ALERT" />Alert</label>
                        <em className="small float-right">Use {`${`{{name}}`}`} for replace Partner Name in Title/Message</em>
                    </div> */}
                    <InputFeild required placeholder="Title" name="title" defaultValue={this.props.defaultNotificationTitle || ""} onChange={(e) => this.setState({ title: e.target.value})} />
                    <textarea value={this.state.message} rows={5} onChange={(e) => this.setState({message: e.target.value})} required name="message" placeholder="Message" className="form-control mt-3" type="text" />
                    
                    <Row className="mt-3 small">
                        <Col><span>Attach Image</span></Col>
                        <Col>
                            <input type="file" accept=".png,.jpg,.jpeg.gif" onChange={(e) => this.setState({ image_file: e.target.files[0] })}  />
                        </Col>
                    </Row>
                    
                    { !campaignNotification && <label className="radio-inline my-2"><input type="checkbox" className="mr-3" checked = {this.state.isRemoveAlreadySent} onChange={(e) => this.setState({isRemoveAlreadySent: e.target.checked})}/>Exclude users who already got notification today</label>}
                    {this.state.isRemoveAlreadySent && <div className="d-inline-block"><InputFeild name="isRemoveAlreadySent" defaultValue="1" required type="number" className="mt-2 ml-3 d-inline-block" placeholder="Enter Notification counts"  min="1" max="10" /></div> }
                    <br/>
                   <label className="mt-2">Select Notification Redirection Screen: </label><br />
                    {CONSTANTS.NOTIFICATION_ORIGIN.map(origin => 
                        <Chip size="small" label={origin.title} onClick={(e) => {sendNotificationOpts.originType = origin.id; this.setState({sendNotificationOpts})}} style={{background: sendNotificationOpts.originType === origin.id ? "#999": "#ddd"}} clickable className="mr-2"/>
                    )}
                    <Row className="mt-2">
                        {["JOB_TASK", "TASK_LEADERBOARD"].indexOf(sendNotificationOpts.originType) !== -1 && <Col>
                            <SelectList search={true} placeholder="Select Project" value={sendNotificationOpts.job_id || ""} onChange ={(e) => { sendNotificationOpts.job_id = e.target.value; sendNotificationOpts.job_task_id = null; this.setState({ sendNotificationOpts }); this.fetchJobTaskForNotification(e.target.value) } } >
                            { 
                                jobList.map(job => 
                                <option key={job.job_id} value={job.job_id}> 
                                    {job.Employer.employer_name +  "  -  "  + job.title} 
                                    {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                                </option>
                                )
                            }
                            </SelectList>
                        </Col>}
                    </Row>



                    {this.state.isSms && <span className="mt-3" style={{fontSize: 12}}><b>Example:</b> {this.state.smsTemplateId && this.state.smsTemplateList.filter((t) => t.template_id == this.state.smsTemplateId)[0].example}</span>}
                    <Row className="align-items-center mt-3 border-top pt-3">
                        {campaignNotification &&  
                        <Col xs={8}>
                            <ReactDatePicker
                                name="scheduled_on"
                                selected={this.state.scheduled_on && moment(this.state.scheduled_on).toDate()}
                                onChange={(e) => this.setState({ scheduled_on: e})}
                                shouldCloseOnSelect={true}
                                minDate={moment().toDate()}
                                maxDate={moment().add(30, "days").toDate()}
                                isClearable={true}
                                placeholderText="Schedule on"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                            />
                        </Col>}
                        <Col className="text-right ">
                            {!campaignNotification && <em className="text-muted">Notification will be sent to {userCount} partners</em>}
                            <Button type="submit" variant="contained" className="ml-3" >{this.state.scheduled_on ? "Schedule" :"Send Now"}</Button>{' '}
                            {/* <Button type="button" variant="outlined"  onClick={this.props.onClose}>Cancel</Button> */}
                        </Col>
                    </Row>
                </form>
            
            
        );
    }
}
export default SendNotifications;

import Axios from "axios";
import ApiEndpoints from "../../src/constants/api_endpoints";

export const uploadFile = async (file, purpose, setProgress) => {
  let fileBody = { file_name: file.name, file_purpose : purpose };
  try {
    let fileResp = await ApiEndpoints.Files.filePutUrl(fileBody);
    let file_url = fileResp.data.data.url;
    if (!file_url) throw new Error("Unable to upload file. Please try again later.");

    var config = { headers: { "Content-Type": file.type }}
    if(setProgress){
      config.onUploadProgress= (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setProgress(percentCompleted);
      }
    }
    await ApiEndpoints.Files.uploadFileToS3(file_url, file, config);
    fileBody.file_key = fileResp.data.data.key;
    var resp = await ApiEndpoints.Files.generateFileID(fileBody);
    return resp.data.data;
  } catch (e) {
    console.log(e)
    throw new Error("Unable to upload file. Please try again later.");
  }
};

export const copyCodeToClipboard = (text) => {
  var textField = document.createElement('textarea')
  textField.innerText = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
}
export const downloadApiFile = (resp, fileName) => {
  const url = window.URL.createObjectURL(new Blob([resp.data]));
  const link = document.createElement('a');
  link.href = url;
  link.download = true;
  const contentDisposition = resp.headers['content-disposition'];
  if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2)
          fileName = fileNameMatch[1];
  }
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}
export const addUser = async data => {
  let opts = {...data};
  try {
    if (!opts.user_id) {
      let user = await ApiEndpoints.User.create(opts);
      if (!user.data.data.user_id) throw new Error("Not Able to create user");
      opts.user_id = user.data.data.user_id;
    }
    if(opts.role_id && !opts.user_role_id){
       let userRoleResp = await ApiEndpoints.User.mapRole(opts);
       if(!userRoleResp.data.data.user_role_id) throw new Error("Not able to associate Role with User")
       opts.user_role_id = userRoleResp.data.data.user_role_id;
    }
    if(opts.user_id && opts.user_role_id && opts.access_type){
        await ApiEndpoints.User.mapAccess(opts);
    }
    return opts;
  } catch (e) {
    throw e;
  }
};

export const  groupArrayOnKey = (data = [], key, isSingleObjectWithKey) => {
  data = data.reduce((prev, curr) => {
    if(isSingleObjectWithKey) prev[curr[key]] = curr;
    else{
      prev[curr[key]] = prev[curr[key]] || [];
      prev[curr[key]].push(curr);
    }
    return prev;
  }, {});
  return data;
};

export const getTextTranslation = async (text, source, target) => {
  if(!text) return "";
  let params = {
    key: "AIzaSyAB1YEc2_e-sBDX7IIpluHMFSTannakxN8",
    q: text,
    source, 
    target
  }
  return Axios.get("https://translation.googleapis.com/language/translate/v2", { params }).then(resp => 
    resp.data.data && resp.data.data.translations && resp.data.data.translations[0] ? resp.data.data.translations[0].translatedText : ""
  )
}

export const accessExotelCallRecording = (url) => {
  if(!url) return "";
  url = url.replace("recordings.exotel.com", "0c16561a70ef00d34f39ead6184bb2ee8fdbe2ca5e04dfa6:c8a76e82861e627343d741e6f2708bab1251964075462d41@recordings.exotel.com");
  return url;
}

export const getTextTranslationWithLang = async (text, source, target,info_type) => {
  let types = {
    "en":"ENGLISH",
    "hi":"HINDI",
    "ta":"TAMIL",
    "te":"TELUGU",
    "kn":"KANNADA"
  }
  if(!text) return "";
  let params = {
    key: "AIzaSyAB1YEc2_e-sBDX7IIpluHMFSTannakxN8",
    q: text,
    source, 
    target,
    format:"text"
  }
  return Axios.get("https://translation.googleapis.com/language/translate/v2", { params }).then(resp => 
    {
      return {
        info_type:info_type,
        language:types[target],
        content:resp.data.data && resp.data.data.translations && resp.data.data.translations[0] ? resp.data.data.translations[0].translatedText : ""
      }
    }
  )
}


export const getAmountIntoWords = (num = 0) => {
  var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
  var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

  if ((num = num.toString()).length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
  return str;
}
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import DUMMY_PROFILE_IMG from "../../../assets/images/dummy_profile.jpg";
import PartnerDetail from "../user_profile";
import moment from "moment";
import CONSTANTS from "../../../constants";


class UserCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenUserModel: null 
        }
    }

    componentDidMount(){
    }

   

    render() {
        // let {} = this.state;
        let { data = {}, showProfilePic = true } = this.props;
        let uninstalledOn = data.uninstalled_on ? data.uninstalled_on : (data.UserConfigs && data.UserConfigs[0] && data.UserConfigs[0].uninstalled_on)
        let badgeLevel = data.UserConfigs && data.UserConfigs[0] ? data.UserConfigs[0].badge_level : data.badge_level;
        return (
        <>        
            <Row noGutters className="align-items-center">
                {showProfilePic && 
                <Col xs={"auto"} className="p-2" style={{position:"relative"}}>
                    <img className="rounded-circle border" style={{width: "35px", aspectRatio: "1", fontSize: 8, display: "block"}} src={data.profile_picture_url ? data.profile_picture_url : DUMMY_PROFILE_IMG} onError={(e)=>{e.target.onerror = null; e.target.src=DUMMY_PROFILE_IMG}}  alt="Profile Pic.." />
                    <img src={badgeLevel >=0 ? require('../../../assets/images/' + CONSTANTS.badgeConfig[badgeLevel].iconSvgName):""} alt='' style={{height:"18px", position: "absolute", top: 3, left: 3}} />
                    {badgeLevel >= 0 && <span className="d-block font-weight-bold small" style={{fontSize: 10, color: CONSTANTS.badgeConfig[badgeLevel].baseColor}}>{CONSTANTS.badgeConfig[badgeLevel].name}</span>}
                </Col>
                }
                <Col >
                    <b onClick={() => this.setState({isOpenUserModel: true})} style={{cursor : "pointer"}}>{data.first_name ? data.first_name : ""}</b>
                    <span className="d-block" style={{fontSize:12, cursor: "pointer"}}>
                    <span onClick={() => this.setState({isOpenUserModel: true})}>{data.id ? "PMW" + data.id : "-"}</span>
                        {!!uninstalledOn && <span className="px-1 border shadow-sm ml-2" style={{borderRadius: 30, fontSize: 10,color:"#f1061c",fontWeight: 650}}>Uninstalled-{moment( uninstalledOn || moment().toDate() ).utcOffset("+05:30").format("Do MMM'YY")}</span>}
                        {data.consumer_type === 2 &&
                         <span className="px-1 bg-light border shadow-sm ml-2" style={{borderRadius: 30, fontSize: 10, fontWeight: 650}}>Shop Owner</span>}
                    </span>    
                    {/* <em style={{fontSize: 12}}>{data.User && data.User.mobile ? "Mobile : " + data.User.mobile : data.mobile ? "Mobile : " + data.mobile : "" }<br /></em> */}
                    <em style={{fontSize: 12}}>{data.city ? data.city : data.UserConfigs && data.UserConfigs[0] && data.UserConfigs[0].city ? data.UserConfigs[0].city : "" }</em> 
                    <em style={{fontSize: 12}}>{data.state ? ", "+data.state  : data.UserConfigs && data.UserConfigs[0] && data.UserConfigs[0].state ? ", "+data.UserConfigs[0].state : ""}</em> 
                </Col>
            </Row>
            {!!this.state.isOpenUserModel && <PartnerDetail {...data} toggle={() => this.setState({isOpenUserModel : false})} />}
         </>   
        );
    }
}
export default UserCard;


import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import InputFeild from "../../global/form/input"
import "../../../styles/button.css"
import {Icon, Switch} from '@material-ui/core';
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { Input, FormGroup } from 'reactstrap';
import ConfirmAction from "../../global/confirm-action";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

class JobPayment extends Component {
    constructor(props) {
      super(props);
      this.state = {
           isAddNew : false,
           paymentData : [] ,
           isConfirm : false,
           isShowOnlyActivePayments: true,
           isEditPayment: false
        }
    }

    async componentDidMount(){
        await this.init() ;
    }

    init = async () =>{
        store.dispatch(setLoader(true));
        var body = {
            job_id : this.props.job.job_id
        }
        if(this.props.jobTask){
            body.ref_type = "JOB_TASK";
            body.ref_id = this.props.jobTask.job_task_id;
        }
        try{
            let apiData = await ApiEndpoints.JobTaskPayment.fetch(body);
            let paymentData = apiData.data.data.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
            this.setState({paymentData}) ;
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    setIsAddNewVale = (k, v) => {
        let { isAddNew } = this.state;
        isAddNew[k] = v;
        if(k === "payment_type"){
            if(v === "ORDER_RATIO") isAddNew.target_type = "INPUT_ID";
            if(v === "FIXED") isAddNew.target_type = "TASK_COUNT";
            if(v === "ACTIVATION") isAddNew.target_type = "STATUS2";
        } 
        this.setState({ isAddNew });
    }
   
    addPayment = async (e) =>{
        e.preventDefault();
        e.persist();
        let { isAddNew, paymentData } = this.state;
        let isAlreadyErrorSet = !!isAddNew.error;
        let { payment_type, target_type, target_value, amount, comment, instruction, start_date, end_date } = isAddNew;
        let body = {
            job_id: this.props.job.job_id,
            payment_type,
            target_type: target_type || "TASK_COUNT",
            target_value,
            job_location_id:0,
            amount,
            comment,
            instruction,
            start_date, 
            end_date
        } 
        let checkError = this.validateAddPaymentTimeConflict(isAddNew);
        if(checkError.error){
            if(!isAlreadyErrorSet) return this.setState({ isAddNew: checkError });
            else {
                await Promise.all(checkError.errorJobPaymentId.map(id => ApiEndpoints.JobTaskPayment.update({ job_payment_id: id, end_date: start_date })))
            }
        }

        store.dispatch(setLoader(true));
        if(this.props.jobTask){
            body.ref_type = "JOB_TASK";
            body.ref_id = this.props.jobTask.job_task_id;
        }
        try {
            var resp = await ApiEndpoints.JobTaskPayment.add(body);

            paymentData.push(resp.data.data)
            e.target.reset();
            this.setState({paymentData, isAddNew: false})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    validateAddPaymentTimeConflict = (isAddNew) => {
        let { paymentData } = this.state;
        let { payment_type, target_type, target_value, start_date, end_date } = isAddNew;
        isAddNew.errorJobPaymentId = paymentData.filter(payment => {
            if(payment.payment_type === payment_type && payment.target_type === target_type && payment.target_value === parseInt(target_value)){
                if(!(payment.end_date && moment(start_date).isAfter(payment.end_date))) return true;
            }
            return false;
        }).map(o => o.job_payment_id);
        if(isAddNew.errorJobPaymentId.length) isAddNew.error = "There is some conflict with existing payment timeline";
        return isAddNew;
    }

    editJobPayment = async (isEditPayment) =>{
        let {paymentData} = this.state;
        store.dispatch(setLoader(true))
        let body = { 
            job_payment_id: isEditPayment.job_payment_id,
            comment: isEditPayment.comment,
            instruction: isEditPayment.instruction,
            end_date: isEditPayment.end_date
        } 
        try {
            await ApiEndpoints.JobTaskPayment.update(body);
            paymentData = paymentData.map(pay => {
                if(pay.job_payment_id === isEditPayment.job_payment_id) pay = isEditPayment;
                return pay;
            })
            this.setState({paymentData, isEditPayment: false})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    deletedPayment = async (job_payment_id) =>{
        let {paymentData} = this.state;
        store.dispatch(setLoader(true))
        let body = { job_payment_id } 
        try {
            await ApiEndpoints.JobTaskPayment.delete(body);
            paymentData = paymentData.filter( obj => { return obj.job_payment_id !== job_payment_id });
            this.setState({paymentData})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    
    render(){
        let {isAddNew, paymentData , isConfirm, isEditPayment} = this.state;
        let { jobTask } = this.props;
        let activationStatusData = jobTask.formData && jobTask.formData.response_status && jobTask.formData.response_status.status2;
        let systemInputs = [];
        jobTask.formData && jobTask.formData && jobTask.formData.input.sections.map(section => 
            section.input_groups.forEach(inputGroup => 
            inputGroup.inputs.forEach(input => { input.input_by === "SYSTEM" && systemInputs.push(input) })))

        return(
        <div>
            <Row>
                <Col>
                    <Button id="add_payment_structure_entry" onClick={()=> {this.setState({isAddNew : {}})}} className="buttonred mb-3" style={{height: "23px", paddingTop: "1px"}}>Add Payment</Button>{' '}
                </Col>
                <Col className="text-right">
                    <span className="small">Is show only active payments?</span>
                    <Switch
                        onChange={(e) => this.setState({isShowOnlyActivePayments : e.target.checked})}
                        checked={this.state.isShowOnlyActivePayments}
                        size="small"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {!!isAddNew &&
                    <form onSubmit={this.addPayment} className="p-2 bg-light my-3" style={{borderRadius: 8}}>
                        <Row>
                            <Col xs={5}>
                                <InputFeild required placeholder="Payment Title (Ex: Trade Done)" value={isAddNew.comment} onChange={e =>  this.setIsAddNewVale("comment", e.target.value)} />
                            </Col>
                            <Col>
                                <InputFeild placeholder="Payment Instruction (Ex: Trade Done within 15 days)" value={isAddNew.instruction} onChange={e =>  this.setIsAddNewVale("instruction", e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>
                                <ReactDatePicker
                                    selected={!!isAddNew.start_date && moment(isAddNew.start_date).toDate()}
                                    onChange={date =>  this.setIsAddNewVale("start_date", date)}
                                    dateFormat='dd-MMM-yyyy HH:mm'
                                    placeholderText="Start Date"
                                    isClearable
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={60}
                                    minDate={moment().toDate()}
                                />
                            </Col>
                            <Col xs={6}>
                                <ReactDatePicker
                                    selected={!!isAddNew.end_date && moment(isAddNew.end_date).toDate()}
                                    onChange={date =>  this.setIsAddNewVale("end_date", date)}
                                    dateFormat='dd-MMM-yyyy HH:mm'
                                    placeholderText="End Date (Optional)"
                                    isClearable
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={60}
                                    shouldCloseOnSelect={true}
                                    timeCaption="time"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={3}>
                                <Input onChange={(e) => this.setIsAddNewVale("payment_type", e.target.value)} required name="payment_type" id="add_payment_structure_input1" type="select">
                                    <option  value="">Select</option>
                                    {Object.keys(paymentType).map(status =>  <option key={status} value={status}>{paymentType[status]}</option>)}
                                </Input>
                            </Col>
                            <Col xs={3}>
                                {isAddNew.payment_type === "ACTIVATION" ? 
                                <Input required name="target_value" type="select" onChange={(e) => this.setIsAddNewVale("target_value", e.target.value)}>
                                    <option  value="">Select</option>
                                    {
                                        activationStatusData ? Object.keys(activationStatusData).map(activationStatus => {
                                            var statusData = activationStatusData[activationStatus];
                                            return statusData.status === 1 ? <option key={activationStatus} value={activationStatus}>{statusData.label}</option> : <></>
                                        })
                                        :
                                        <option disabled>No Activation Configuration</option>
                                    }
                                </Input>
                                :
                                isAddNew.payment_type === "ORDER_RATIO" ? 
                                <Input required name="target_value" type="select" onChange={(e) => this.setIsAddNewVale("target_value", e.target.value)}>
                                    <option  value="">Select</option>
                                   {systemInputs.map(input => <option  value={input.input_id}>{input.label}</option>)}
                                </Input>
                                :
                                <InputFeild required name="target_value" id="add_payment_structure_input2" placeholder="Target Value" onChange={(e) => this.setIsAddNewVale("target_value", e.target.value)} />
                            }
                            </Col>
                            <Col xs={3}>
                                <InputFeild required name="amount" inputProps={{min: 1}} type="number" id="add_payment_structure_input3" placeholder="Amount (INR)" onChange={(e) => this.setIsAddNewVale("amount", e.target.value)} />
                            </Col>
                            <Col className="text-right"> 
                                <Button className="p-0 mr-3" color="link"><Icon id="add_payment_structure_submit" className="" style={{color: "#000", fontSize: "18px"}}>done</Icon> </Button>
                                <Icon id="add_payment_structure_cancel" style={{cursor: "pointer", fontSize: "18px"}} onClick={() => this.setState({isAddNew : false})}>close</Icon>
                            </Col>
                        </Row>
                        <span className="small text-danger my-2">{isAddNew.error}</span>
                    </form>
                    }

                    { paymentData.map((payment, i)=> {
                    let isActivePayment = moment().isBefore(moment(payment.start_date)) ? 2 : moment().isBetween(moment(payment.start_date), moment(payment.end_date || moment().add(1, "day"))) ? 1 : 0;
                    if(this.state.isShowOnlyActivePayments && isActivePayment !== 1) return <></>; 
                    
                    return <div key={i} className="shadow-sm px-3 py-2 my-2" style={{borderWidth: 1, borderStyle: "solid", borderRadius: 8, backgroundColor: isActivePayment === 2 ? "#cccccc15" : isActivePayment === 1 ? "#00ff0015" : "#ff000015", borderColor: isAddNew && isAddNew.errorJobPaymentId && isAddNew.errorJobPaymentId.indexOf(payment.job_payment_id) > -1 ? "#ff0000" : "#eee"}}>
                        <Row className="align-items-center" style={{fontSize: "14px"}}>
                            <Col xs={5}>
                                <span className="d-block font-weight-bold">{payment.comment}</span>
                                {!!payment.instruction && <span className="d-block small">{payment.instruction}</span>}
                                <span>{paymentType[payment.payment_type]} - </span>
                                <span>
                                {payment.payment_type === "ACTIVATION" ? 
                                    jobTask.formData && jobTask.formData.response_status && jobTask.formData.response_status.status2 && jobTask.formData.response_status.status2[payment.target_value] && jobTask.formData.response_status.status2[payment.target_value].label
                                    :
                                payment.payment_type === "ORDER_RATIO" ? 
                                systemInputs.filter(input => input.input_id === payment.target_value)[0]?.label
                                    : 
                                    payment.target_value
                                }
                                </span>
                            </Col>
                            <Col xs={2}>₹{payment.amount}</Col>
                            <Col xs={4} className="text-center">
                                <span className="small d-block">{moment(payment.start_date).format("Do MMM'YY HH:mm")} - {payment.end_date ? moment(payment.end_date).format("Do MMM'YY HH:mm") : "Ongoing"}</span>
                                <span className="small d-block text-muted">{moment(payment.created_on).format("Do MMM'YY HH:mm")}</span>
                            </Col>
                            <Col xs={1} className="text-right"> 
                                <Icon id="edit_payment_structure" onClick={() => this.setState({isConfirm: () => this.setState({ isEditPayment: payment }) }) } style={{cursor: "pointer", fontSize: "16px"}}>edit</Icon>
                                {moment().diff(moment(payment.created_on), "minutes") <= 60 && <Icon id="delete_payment_structure" onClick={() => this.setState({isConfirm: () => this.deletedPayment(payment.job_payment_id) }) } style={{cursor: "pointer", fontSize: "16px"}}>delete</Icon>}
                            </Col>
                        </Row>
                        {!!isEditPayment && isEditPayment.job_payment_id === payment.job_payment_id && 
                        <div className="p-4 my-2 bg-light shadow border-light">
                            <Row>
                                <Col xs={5}>
                                    <InputFeild required placeholder="Payment Title (Ex: Trade Done)" value={isEditPayment.comment} onChange={e =>  {isEditPayment.comment = e.target.value; this.setState({isEditPayment})}} />
                                </Col>
                                <Col>
                                    <InputFeild placeholder="Payment Instruction (Ex: Trade Done within 15 days)" value={isEditPayment.instruction} onChange={e =>  {isEditPayment.instruction = e.target.value; this.setState({isEditPayment})}} />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={6}>
                                    <ReactDatePicker
                                        selected={!!isEditPayment.start_date && moment(isEditPayment.start_date).toDate()}
                                        dateFormat='dd-MMM-yyyy HH:mm'
                                        placeholderText="Start Date"
                                        disabled
                                    />
                                </Col>
                                <Col xs={6}>
                                    <ReactDatePicker
                                        selected={!!isEditPayment.end_date && moment(isEditPayment.end_date).toDate()}
                                        onChange={date =>  {isEditPayment.end_date = date; this.setState({isEditPayment})}}
                                        dateFormat='dd-MMM-yyyy HH:mm'
                                        placeholderText="End Date (Optional)"
                                        isClearable
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        shouldCloseOnSelect={true}
                                        timeCaption="time"
                                        minDate={moment().toDate()}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="text-right"> 
                                    <Button className="p-0 mr-3" color="link"><Icon id="add_payment_structure_submit" className="" style={{color: "#000", fontSize: "18px"}} onClick={() => this.editJobPayment(isEditPayment)}>done</Icon> </Button>
                                    <Icon id="add_payment_structure_cancel" style={{cursor: "pointer", fontSize: "18px"}} onClick={() => this.setState({isEditPayment : false})}>close</Icon>
                                </Col>
                            </Row>
                        </div>
                        }
                    </div>
                    })
                }
                </Col>
            </Row>

            <ConfirmAction isOpen={!!isConfirm} onClose={() => this.setState({isConfirm : false})} callback={isConfirm} />
        </div>
        


        );
    }
}

export default JobPayment;

const paymentType = {
    "FIXED": "Fix Pay",
    "ORDER_RATIO": "Order Percentage",
    "DAILY_INCENTIVE": "Daily Incentive",
    "CYCLE_INCENTIVE": "Cycle Incentive",
    "ACTIVATION": "Activation Bonus"
}

import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { groupArrayOnKey } from "../../../actions";
import { TablePagination } from "@material-ui/core";
import moment from "moment";


class UserWalletDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentWalletBalance: 0,
            balanceData : {},
            transactions: [],
            balanceByProjects: [],
            currentTransactionPage: 0,
            selectedDateFilter: 0
        }
    }

    componentDidMount(){
       this.selectDateFilter();
    }

    selectDateFilter = async (dateFilterIndex) => {
        let filters = {};

        if(dateFilterIndex === 1) {
            filters.start_date = moment().startOf("month").toDate();
            filters.end_date = moment().endOf("month").toDate();
        }
        if(dateFilterIndex === 2) {
            filters.start_date = moment().subtract(1, "month").startOf("month").toDate();
            filters.end_date = moment().subtract(1, "month").endOf("month").toDate();
        }
        this.setState({ selectedDateFilter: dateFilterIndex })

        store.dispatch(setLoader(true))
        await this.fetchBalance(filters);
        await this.fetchBalanceByProjects(filters);
        store.dispatch(setLoader(false))

        this.fetchTransactions(filters);
    }

    fetchBalance = async (filters = {}) => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id, ...filters }
            let resp = await ApiEndpoints.Userwallet.fetchUserBalance(body);
            let balanceData = resp.data.data[0] || {};

            let currentWalletBalance = Object.keys(filters).length === 0 ? balanceData.total_amount : this.state.currentWalletBalance; //to lock the balance on initial load only, prevent change amount on filter apply
            this.setState({ balanceData, currentWalletBalance  })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchBalanceByProjects = async (filters = {}) => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id, ...filters }
            let resp = await ApiEndpoints.Userwallet.fetchUserBalanceDetails(body)
            
            this.setState({ balanceByProjects : resp.data.data })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchTransactions = async (filters = {}) => {
        try{
            var body = { user_id : this.props.user_id, ...filters }
            let resp = await ApiEndpoints.Userwallet.fetchTransactions(body)
            let transactions = resp.data.data;
            this.setState({ transactions })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    
    
    render() {
        let { balanceData, balanceByProjects, transactions, selectedDateFilter } = this.state;
        return (
            <div className="px-1">
                <span style={{fontWeight: 600}} className="my-3 pb-3 border-bottom d-block">Current Wallet Balance:{" "} ₹{this.state.currentWalletBalance || 0}</span>
                

                <div className="mt-2 mb-4">
                   { 
                    ["All Earning", "Current Month", "Last Month"].map((dateFilter, i) => 
                        <span className={`mr-2 px-2 py-1 d-inline-block border small clickable ${selectedDateFilter === i ? "bg-dark text-light" : "bg-light"}`} style={{borderRadius: 50}} onClick={() => this.selectDateFilter(i)}>
                            {dateFilter}
                        </span>
                    )
                   }
                </div>

                <Row noGutters className="align-items-center mb-3">
                    <Col xs={4}>
                        <div className="bg-light border p-2 text-center mx-1" style={{ borderRadius: 10 }}>
                            <span style={{fontWeight: 600}}>₹{balanceData.credit_amount || 0}</span>
                            <span className="small d-block">All Earning</span>
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="bg-light border p-2 text-center mx-1" style={{ borderRadius: 10 }}>
                            <span style={{fontWeight: 600}}>₹{balanceData.referral_amount || 0}</span>
                            <span className="small d-block">Referral Earning</span>
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="bg-light border p-2 text-center mx-1" style={{ borderRadius: 10 }}>
                            <span style={{fontWeight: 600}}>₹{balanceData.tds_deduction_amount || 0}</span>
                            <span className="small d-block">TDS</span>
                        </div>
                    </Col>
                </Row>
                {
                    balanceByProjects.map((job, i) =>  
                        <Row key={i} className={"align-items-center bg-light py-2 border mt-2"} style={{borderRadius: 10}}>
                            <Col xs={"auto"} className="pr-0"><img alt="job_logo" src={job.logo_url} style={{width: 35, aspectRatio: "1", borderRadius: "50%"}} /></Col>
                            <Col>
                                <span style={{fontSize: 14, fontWeight: 600}}>{job.job_title}</span>
                            </Col>
                            <Col xs={3} className="small text-right">
                                <span className="d-block">₹ {job.total_credit || 0}</span>
                                <span className="small">Total Earnings</span>
                            </Col>
                        </Row>
                    )
                }

                <h6 className="mt-3 pb-2 border-bottom">Transactions</h6>
                <Row noGutters className="border-bottom my-1" style={{fontSize: 12, fontWeight: 600}}>
                    <Col xs={2}>Amount</Col>
                    <Col xs={10}>Description</Col>
                </Row>
                {
                    transactions
                    .slice(this.state.currentTransactionPage*10, this.state.currentTransactionPage*10 + 10)
                    .map((transaction, i) => 
                        <Row noGutters key={i} className="border-bottom my-1" style={{fontSize: 12}}>
                            <Col xs={2} className={transaction.type === 1 ? "text-success": "text-danger"}>{transaction.type === 1 ? "+": "-"}{transaction.amount}</Col>
                            <Col xs={10}>
                                <span>{transaction.reason}</span>
                                <span className="d-block small">{transaction.remark} | {moment(transaction.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
                            </Col>
                        </Row>
                    )
                }
                <TablePagination rowsPerPageOptions={[15]} component="div" count={transactions.length} rowsPerPage={10} page={this.state.currentTransactionPage}
                    onChangePage={ (e, page) => this.setState({currentTransactionPage : page}) }
                />
            </div>
           
        );
    }
}
export default UserWalletDetails;


import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, Table } from 'reactstrap';
import {TablePagination, Icon, Checkbox} from '@material-ui/core';
import { ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css";
import { downloadApiFile, groupArrayOnKey, copyCodeToClipboard} from "../../../actions";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import DatePicker from "react-datepicker";
import moment from "moment";
import CONSTANTS from "../../../constants";
import SelectList from "../../global/form/select";
import InputField from "../../global/form/input";
import { TrackEvent } from "../../../actions/ga"
import UserCard from "../user_profile/user_card";
import ConfirmAction from "../../global/confirm-action";
import OutboundCustomerCallingStatus from "../../global/outbound-customer-calling-status";
import CustomerProfile from "../customer_profile";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg"
import TaskLeadAdd from "./task_lead_add";

class TaskData extends Component {
    constructor(props) {
      super(props);
      this.state = {
            taskCount: 0,
            taskData: [],
            isOpenConfirm : false,
            confirmMessage: null,
            rowsPerPage: 50,
            currentUserPage: 0,
            filters: {
                status: props.selectedStatus && CONSTANTS.VERIFICATION_STATUS[props.selectedStatus],
                start_date: props.selectedTaskCycle && props.selectedTaskCycle.start_date,
                end_date: props.selectedTaskCycle && props.selectedTaskCycle.end_date
            },
            taskReasons: {},
            callCustomerDetails: null,
            isShowCustomerDetails: false,
            historyData : [],
            isAddCustomerRemark: null,
            isAddNewLead: false,
            selectedFormResponse: [],
            isOpenUpdateFormResponse: false
        }
    }

    async componentDidMount(){
        this.fetchTaskReasons();
        this.fetchUserTask();
    }

    fetchTaskReasons = async () => {
        let { jobData } = this.props;
        try{
            var body = {job_id: jobData.parent_job_id || jobData.job_id};
            var reasonResp = await ApiEndpoints.JobTask.fetchTaggedReason(body);
            let taskReasons = reasonResp.data.data.reduce((prev, curr) => {
                if(curr.reason_text && curr.reason) prev[curr.reason.toLowerCase()] = curr.reason_text;
                return prev;
            }, {}); 
            this.setState({ taskReasons });

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    fetchTaskHistory = async (formResponseId, entity_type) => {
        store.dispatch(setLoader(true))
        try{   
            var body = { 
                form_response_id: formResponseId,
                entity_type
            }    
            let resp = await ApiEndpoints.JobTask.fetchTaskHistoryData(body);
             this.setState({historyData : resp.data.data})
             
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchUserTask = async (currentUserPage = 0) => {
        let { jobTask} = this.props;
        store.dispatch(setLoader(true))
        let { rowsPerPage, filters} = this.state;
        if(filters.start_date) filters.start_date = moment(filters.start_date).startOf("day").toDate();
        if(filters.end_date) filters.end_date = moment(filters.end_date).endOf("day").toDate();
        if(filters.search_user && filters.search_user.toLowerCase().includes("pmw")) filters.search_user = filters.search_user.toLowerCase().replace("pmw", "");
        try{
          let formResBody = {
            form_id : jobTask.form_id,
            limit: rowsPerPage,
            offset: currentUserPage * rowsPerPage,
            ...filters
          }
          if(filters.search_data_value && filters.search_data_value.toLowerCase().includes("ref")){
            if(filters.search_data_value.toLowerCase().match(/ref\d+/g)){
                formResBody.form_response_id = parseInt(filters.search_data_value.toLowerCase().replace("ref", ""));
                formResBody.search_data_value = "";
            }
        }
          if(formResBody.sub_status === "2") formResBody.sub_status = [0, 2];
          if(this.props.isShowExpired) formResBody.archive_status = 1;
          else formResBody.archive_status = 0;
          if(jobTask.formData && jobTask.formData.response_data_type === "GROUP_COUNT"){
            let response = await ApiEndpoints.JobTask.fetchGroupedTaskResp(formResBody);
            let responseData = response.data.data.reduce((prev, curr) => {prev[curr.status] = curr; return prev;}, {})
            this.setState({taskData : [{
                VERIFIED : responseData["VERIFIED"] ? parseInt(responseData["VERIFIED"].response_counts)  : 0,
                PENDING : responseData["PENDING"] ? parseInt(responseData["PENDING"].response_counts)  : 0,
                REJECTED : responseData["REJECTED"] ? parseInt(responseData["REJECTED"].response_counts)  : 0,
              }]});
          }
          else{
            let response = await ApiEndpoints.JobTask.fetchTaskRespWithUser(formResBody);
            let taskData = response.data.data.rows || [];
            let taskCount = response.data.data.count || 0;
            this.setState({currentUserPage, taskData, taskCount});
          }
        }
        catch(e){
            console.log(e)
          this.setState({taskData : []})
          store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }));
        }
        store.dispatch(setLoader(false))
      }

      changeFilter = (key, value) => {
        let { filters } = this.state;
        if(value === "" || value === undefined || value === null) delete filters[key];
        else filters[key] = value;
        this.setState({filters});
      }


      playVideo = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileResp = await ApiEndpoints.Files.getFileID({file_id: fileId});
            this.setState({videoPlayUrl : fileResp.data.data[0].url})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    showImage = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileResp = await ApiEndpoints.Files.getFileID({file_id: fileId});
            this.setState({showImageUrl : fileResp.data.data[0].url})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    showFile = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileResp = await ApiEndpoints.Files.getFileID({file_id: fileId});
            const newWindow = window.open(fileResp.data.data[0].url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    downloadTask = async (opts = {}) => {
        TrackEvent("TASK_DATA", "TASK_DATA_DOWNLOAD", "TASK_DATA_DOWNLOAD");
        store.dispatch(setLoader(true));
        let { jobTask} = this.props;
        let { filters } = this.state;
        if(filters.start_date) filters.start_date = moment(filters.start_date).startOf("day").toDate();
        if(filters.end_date) filters.end_date = moment(filters.end_date).endOf("day").toDate();
        try{
          let formResBody = {
            job_task_id: jobTask.job_task_id,
            form_id : jobTask.form_id,
            ...filters
          }
          if(formResBody.sub_status === "2") formResBody.sub_status = [0, 2];
          if(this.props.isShowExpired) formResBody.archive_status = [0, 1];
            if(jobTask.formData.response_data_type === "GROUP_COUNT"){
                var resp1 = await ApiEndpoints.JobTask.downloadFormGroupedResponse(formResBody);
                downloadApiFile(resp1, "Task Response.csv")
            }
            else{
                var resp2 = await ApiEndpoints.JobTask.downloadFormResponse(formResBody);
                downloadApiFile(resp2, "Task Response.csv")
            }
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    downloadTaskMediaData = async (opts = {}) => {
        TrackEvent("TASK_DATA", "TASK_MEDIA_DATA_DOWNLOAD", "TASK_MEDIA_DATA_DOWNLOAD");
        store.dispatch(setLoader(true));
        let { jobTask} = this.props;
        let { filters } = this.state;
        if(filters.start_date) filters.start_date = moment(filters.start_date).startOf("day").toDate();
        if(filters.end_date) filters.end_date = moment(filters.end_date).endOf("day").toDate();
        let user = store.getState().user;
        try{
            let alreadyRequested = localStorage.getItem('taskMediaDataDownload');
            if(alreadyRequested && moment().subtract(30, "minutes").isBefore(moment(alreadyRequested))) throw new Error("A request already is in process, Please try after sometime");
            if(!user.email) throw new Error("No Email-ID added in account");
            let formResBody = {
                job_task_id: jobTask.job_task_id,
                form_id : jobTask.form_id,
                ...filters
            }
            if(formResBody.sub_status === "2") formResBody.sub_status = [0, 2];
            await ApiEndpoints.JobTask.downloadFormResponseMedia(formResBody);
            localStorage.setItem("taskMediaDataDownload", moment().toDate())
            store.dispatch(setSuccess({show: true, message: "Request Added, Please wait! Data will be send to your email within hour"}))
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    copyFormResponseShortUrl = async (response, inputData) => {
        store.dispatch(setLoader(true));
        try {
            var resp = await ApiEndpoints.UrlShortener.getLink({ ref_type: "FORM_RESPONSE", ref_id: response.form_response_id });
            if(!resp.data.data[0]) {
                await ApiEndpoints.JobTask.generateFormResponseURL({
                    form_id: response.form_id,
                    form_response_id: response.form_response_id,
                    mobile: inputData[1] && inputData[1].value
                });
                resp = await ApiEndpoints.UrlShortener.getLink({ ref_type: "FORM_RESPONSE", ref_id: response.form_response_id });
            }
            if(!resp.data.data[0]) throw new Error("No link found");
            let originalUrl = resp.data.data[0].url_original;
            let url = "";
            if(originalUrl.includes("hdfcbank") || originalUrl.includes("aubank") || originalUrl.includes("icicibank"))
                url = originalUrl;
            else url = resp.data.data[0].url
            copyCodeToClipboard(url); 
            store.dispatch(setSuccess({show: true, message: "Link Copied to your Clipboard!"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

    shareLeadLinkToWhatsapp = async (formResponse, inputData) => {
        store.dispatch(setLoader(true));
        let user = store.getState().user;
        let { jobData } = this.props;
        try {
            var resp = await ApiEndpoints.UrlShortener.getLink({
                ref_type: "FORM_RESPONSE",
                ref_id: formResponse.form_response_id
            });
            if(!resp.data.data[0]) throw new Error("No link found");

            let originalUrl = resp.data.data[0].url_original;
            let link = "";
            if(originalUrl.includes("hdfcbank") || originalUrl.includes("aubank") || originalUrl.includes("icicibank"))
                link = originalUrl;
            else link = resp.data.data[0].url
            window.open(`whatsapp://send?phone=${inputData[1] && inputData[1].value}&text=Hi ${inputData[2] && inputData[2].value}%0a%0aI am ${user.first_name} behalf of ${jobData.Employer.employer_name} to assist you in your credit card application. %0a%0aI am calling you to take you through the next step in the application process. %0a%0aLink- ${encodeURIComponent(link)}`);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }


    connectCustomerCall = async (formResponse) => {
        store.dispatch(setLoader(true));
        let { jobTask } = this.props;
        try{
          if(this.state.callCustomerDetails){
              store.dispatch(setError({show: true, message: "You are already on another call."}))
              store.dispatch(setLoader(false));
              return;
          }
          var body = {
              customer_lead_id : formResponse.customer_lead_id,
              entity_type: "JOB",
              entity_id: jobTask.job_id,
              subentity_type: "FORM_RESPONSE",
              subentity_id: formResponse.form_response_id,
              direction: 2
          }
          let resp = await ApiEndpoints.Calling.connectOutboundCustomerCall(body);
          
          this.setState({callCustomerDetails: { calling_id : resp.data.data.Sid, customerData: resp.data.data.customerData, formResponse }});
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

    saveCustomerRemark = async () => {
        let { isAddCustomerRemark, taskData } = this.state;
        let { jobTask } = this.props;
        if(!isAddCustomerRemark.customer_remark) return this.setState({ isAddCustomerRemark: null })
        store.dispatch(setLoader(true));
        try{
            var body = {
                form_id: jobTask.form_id,
                form_response_id: isAddCustomerRemark.form_response_id,
                customer_remark: isAddCustomerRemark.customer_remark
            }
            let resp = await ApiEndpoints.JobTask.editTaskFormResp(body);
            taskData = taskData.map(response => {
                if(response.form_response_id === isAddCustomerRemark.form_response_id) response.customer_remark = isAddCustomerRemark.customer_remark;
                return response;
            })
          this.setState({ isAddCustomerRemark: null, taskData });
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
       
    }


    submitAddMissingDetails = async (formElem, response, inputData, input_id) => {
        let { taskData } = this.state;
        formElem.preventDefault();
        store.dispatch(setLoader(true));
        let value = formElem.target.input_value.value;
        if(inputData[input_id].value !== value) {
           
            if(inputData[input_id].value) await ApiEndpoints.JobTask.deleteResponseInput({ input_id, value: inputData[input_id].value, form_response_id: response.form_response_id });
            if(value){
                let body = {
                    form_response_id: response.form_response_id,
                    input_id: input_id, 
                    value,
                    mobile: input_id === 1 ? value : (inputData[1] && inputData[1].value),
                    name: input_id === 2 ? value : (inputData[2] && inputData[2].value)
                }
                let resp = await ApiEndpoints.JobTask.addResponseInput(body);
                inputData[input_id] = resp.data.data.createdFormResponseData || body;
                response.customer_lead_id = resp.data.data.customer_lead_id;
            }
        }

        inputData[input_id].isEdit = false;

        taskData = taskData.map(res => {
            if(res.form_response_id === response.form_response_id){
                res.FormResponseData = Object.values(inputData);
                res.customer_lead_id = response.customer_lead_id
            }
            return res;
        });
        this.setState({ taskData });
        store.dispatch(setLoader(false))

    }


    onSelectFormResponse = (e) => {
        let {selectedFormResponse} = this.state;
        if(e.target.checked) selectedFormResponse.push(parseInt(e.target.value));
        else   selectedFormResponse.splice(selectedFormResponse.indexOf(parseInt(e.target.value)), 1)
        this.setState({selectedFormResponse})
    }

    formResponseUpdate = async (e, data) => {
        e.preventDefault();
        let { selectedFormResponse } = this.state;
        let { jobTask } = this.props;
        var selectedTask = jobTask;

        store.dispatch(setLoader(true))
        try {
            var body = {
                form_id: selectedTask.form_id,
                job_task_id: selectedTask.job_task_id,
                status: data.status,
                form_response_id: selectedFormResponse,
                status_comment: data.status_comment,
                status2: data.status2 || null
            }
            await ApiEndpoints.JobTask.verifyResponse(body);
            this.setState({isOpenUpdateFormResponse : false, selectedFormResponse: []})
            await this.fetchUserTask()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    setEditInput = (formInputObj, formResponse, userInputData, isEdit = true) => {
        let { taskData } = this.state;

       
            taskData = taskData.map(response => {
                if(response.form_response_id === formResponse.form_response_id){


                    if(userInputData[formInputObj.input_id]){
                        userInputData[formInputObj.input_id] = userInputData[formInputObj.input_id].isEdit = isEdit;
                    }
                    else {
                        response.FormResponseData.push({ form_response_id: formResponse.form_response_id, input_id: formInputObj.input_id, isEdit: isEdit })
                    }

                }
                return response;
            });

        this.setState({ taskData });
    }


    render() {
        let { taskData, filters, taskReasons, isShowCustomerDetails, historyData, isAddCustomerRemark, isAddNewLead, selectedFormResponse, isOpenUpdateFormResponse } = this.state;
        let { jobTask, jobData } = this.props;
        let user = store.getState().user;
        var userRole = user.UserRole;
        var selectedTask = jobTask;
        var activationStatusData = selectedTask.formData && selectedTask.formData.response_status && selectedTask.formData.response_status.status2;
        let isMediaInputForm = selectedTask.formData && !!selectedTask.formData.input.sections.filter(section => section.input_groups.filter(inputGroup => !!inputGroup.inputs.filter(input => ["VIDEO", "IMAGE", "FILE"].indexOf(input.input_type) === -1).length).length).length
        let formInputs = [];
        if(selectedTask.formData && selectedTask.formData.input)
            selectedTask.formData.input.sections.map(section => 
                section.input_groups.map(inputGroup => 
                    formInputs.push(...inputGroup.inputs)
                )
            )
        const isVerificationAllowed = ["SVPBChhOn8", "yUnEHzXJuj","XEA1YYmQ7R", "h5YDKq6hcu", "znXBQO3F0L", "8r7GgJQofA", "8pLDzq2gDn"].includes(user.user_id);
        return(
            <>
            <form onSubmit={(e)=> { e.preventDefault(); this.fetchUserTask(); }}>
                <Row className={"align-items-end"}>
                    <Col xs={2} className="mb-2">
                        <SelectList value={filters.status} onChange={e => this.changeFilter("status", e.target.value)}>
                            <option value="">All Status</option>
                            <option value={"0"}>Pending</option>
                            <option value={"1"}>Verified</option>
                            <option value={"2"}>Rejected</option>
                        </SelectList>
                    </Col>
                    {!!activationStatusData && <Col xs={3} className="mb-2">
                        <SelectList value={filters.status2} onChange={e => this.changeFilter("status2", e.target.value)}>
                            {
                                Object.keys({ "": "All", ...activationStatusData}).sort().map((status2) => 
                                    <option value={status2}>{activationStatusData[status2] ? activationStatusData[status2].label : "All Activations"}</option>
                            )}
                        </SelectList>
                    </Col>}
                    <Col xs={2} className="mb-2">
                        <SelectList placeholder="Completion - All" value={filters.sub_status} onChange={e => this.changeFilter("sub_status", e.target.value)}>
                            <option value="">All</option>
                            <option value={"1"}>Partial</option>
                            <option value={"2"}>Completed</option>
                        </SelectList>
                    </Col>
                
                    <Col className="mb-2">
                        <DatePicker
                            selected={filters.start_date || null}
                            onChange={date => this.changeFilter("start_date", date)}
                            isClearable={true}
                            autoComplete={false}
                            dateFormat='dd-MMM-yyyy'
                            placeholderText="Start Date"
                        />
                    </Col>
                    <Col className="mb-2 pr-4">
                        <DatePicker
                            selected={filters.end_date || null}
                            onChange={date => this.changeFilter("end_date", date)}
                            isClearable={true}
                            autoComplete={false}
                            dateFormat='dd-MMM-yyyy'
                            placeholderText="End Date"
                        />
                    </Col>
                </Row>
                <Row> 
                    <Col className="mb-2">
                        <InputField style={{fontSize: 12}} onKeyPress={e => { if(e.key === 'Enter') {e.preventDefault(); this.fetchUserTask()}  }} placeholder="Search by Lead Remark"  value={filters.search_customer_remark} onChange={e => this.changeFilter("search_customer_remark", e.target.value)}/>
                    </Col>
                    <Col className="mb-2">
                        <InputField style={{fontSize: 12}} onKeyPress={e => { if(e.key === 'Enter') {e.preventDefault(); this.fetchUserTask()}  }} placeholder="Search by Comments"  value={filters.search_comment} onChange={e => this.changeFilter("search_comment", e.target.value)}/>
                    </Col> 
                    <Col className="mb-2">
                        <InputField style={{fontSize: 12}} onKeyPress={e => { if(e.key === 'Enter') {e.preventDefault(); this.fetchUserTask()}  }} placeholder="Search by Ref-ID/Customer"  value={filters.search_data_value} onChange={e => this.changeFilter("search_data_value", e.target.value)}/>
                    </Col> 
                    <Col className="mb-2">
                        <InputField style={{fontSize: 12}} onKeyPress={e => { if(e.key === 'Enter') {e.preventDefault(); this.fetchUserTask()}  }} placeholder="Search by Partner" value={filters.search_user} onChange={e => this.changeFilter("search_user", e.target.value)}/>
                    </Col>
                    
                    <Col xs={4} className="align-self-end mb-2">
                        <Row className={"justify-content-end"} noGutters>
                            <Col className={"text-right pr-2"}>
                                <Button size="sm" onClick={() => this.fetchUserTask()} color="dark">Apply Filter</Button>
                            </Col>
                            <Col xs="auto" className="pr-2">
                                <Button size="sm" type="button" onClick={() => this.downloadTask()} color="secondary"><Icon className="align-middle" style={{fontSize: 18}}>download</Icon> Data</Button>
                                {/* {!!isMediaInputForm && <Button size="sm" className={"ml-2"} onClick={() => this.downloadTaskMediaData()} color="secondary"><Icon className="align-middle" style={{fontSize: 18}}>download</Icon> Media</Button>} */}
                            </Col>
                            <Col xs="auto">
                                <Button size="sm" type="button" onClick={() => this.setState({ isAddNewLead: isAddNewLead ? false : {} })} color="secondary"><Icon className="align-middle" style={{fontSize: 18}}>add</Icon></Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>

            {
                <Modal size="large" centered isOpen={!!isAddNewLead}>
                    <ModalHeader toggle={() => this.setState({isAddNewLead: false})}><span className="small">Add New Lead</span></ModalHeader>
                    <ModalBody className="" >
                        {!!isAddNewLead && <TaskLeadAdd jobTask={jobTask} onClose={() => this.setState({isAddNewLead: false})} />}
                    </ModalBody>
                </Modal>
                
                // <div className="m-3 p-2 bg-light shadow-sm">
                //     <span className="font-weight-bold small my-2">Add New Lead</span>
                //     <Row>
                //         {formInputs.filter(input => input.input_by !== "SYSTEM").map(input => 
                //             <Col xs={4}>
                //                 <InputField value={isAddNewLead[input.input_id] || ""} onChange={(e) => {isAddNewLead[input.input_id] = e.target.value; this.setState({isAddNewLead})} }  placeholder={input.label} />
                //             </Col>
                //         )}
                //     </Row>
                //     <Row className="justify-content-end">
                //         <Col xs="auto" className="my-2">
                //             <Button size="sm" type="button" color="secondary" onClick={() => this.setState({ isAddNewLead: false })}>Cancel</Button>
                //             <Button size="sm" type="button" color="dark" className="ml-3" onClick={() => this.addNewLead()}>Add Lead</Button>
                //         </Col>
                //     </Row>
                // </div>
            }
            {!!selectedFormResponse.length &&
            <Row className="mt-2 mb-2 mr-3">
                <Col md={1} xs={12}><h6 className="mt-1">Action</h6></Col>
                <Col className="">
                    <Button onClick={() => this.setState({isOpenUpdateFormResponse: {status: "VERIFIED"}})} size="sm" color="danger" className="py-0">Verify</Button>
                    <Button onClick={() => this.setState({isOpenUpdateFormResponse: {status: "REJECTED"}})} size="sm" color="danger" className="ml-2 py-0">Reject</Button>
                </Col>
            </Row>
            }
            
            <div className="table-responsive" style={{minHeight: 370}}>
                <Table size="sm" striped className="table-bordered text-center" >
                    <thead className="sticky-top" style={{fontSize: 14, fontWeight: "bold"}}>
                        <tr>
                            <td></td>
                            <td style={{minWidth: 120}}>REF ID</td>
                            <td style={{minWidth: 120}}>Lead Remark</td>
                            <td style={{minWidth: 120}}>Partner</td>
                            <td style={{minWidth: 80}}>Lead<br />Updated</td>
                            <td style={{minWidth: 100}}>Status Comment</td>
                            {!!activationStatusData && <td style={{minWidth: 100}}>Activation Status</td>}
                           <td style={{minWidth: 80}}>Payout</td>
                            { formInputs.map(input =>  <td key={input.input_id} style={{minWidth: 100}}>{input.label}</td> )}
                            {!!selectedTask.formData.audit && 
                                <>
                                    <td>Audit Status</td>
                                    {selectedTask.formData.audit.inputs.map(input =>  <td>{input.label}</td> )}
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody style={{fontSize: 12}}>
                        {
                        taskData.length ? 
                        taskData.map(response => {
                        var inputData = groupArrayOnKey(response.FormResponseData || [], "input_id", true);
                        return <tr key={response.form_response_id} >
                            <td className="align-middle" style={{borderLeft: `8px solid ${VERIFICATION_COLORS[response.status]}`}}>                                
                                <Checkbox disabled={!isVerificationAllowed} className="p-0" color="primary" size="small"  value={response.form_response_id} checked={selectedFormResponse.indexOf(response.form_response_id) !== -1} onChange={this.onSelectFormResponse} />
                            </td>
                            <td className="visible-on-hover-container align-middle" style={{position: "relative"}}>

                                <Icon className="visible-on-hover-item clickable" style={{fontSize: 18, position: "absolute", top: 0, right: 0}} onClick={() => this.copyFormResponseShortUrl(response, inputData)}>link</Icon>
                               
                                <span className="d-block clickable font-weight-bold" onClick={() => this.setState({ isShowCustomerDetails: response })}>REF{response.form_response_id}</span>
                                <span className="small d-block">{moment(response.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
                                
                                {!!response.customer_lead_id && 
                                <div>
                                    {!!jobData && jobData.job_category_id === 6 && <img src={WhatsappIcon} alt="" className="mr-2" style={{width:16, cursor: "pointer"} } onClick={() => this.shareLeadLinkToWhatsapp(response, inputData)} />}
                                    <Icon className="align-middle clickable " style={{fontSize: 15}} onClick={() => this.setState({isConfirm: () => this.connectCustomerCall(response), isConfirmMessage : "Are you sure to connect with customer?"})}>call</Icon>
                                    {response.totalCalls ? <span className="ml-2" style={{fontSize: 11}} title="Connected-Attempted Calls"> | {response.completedCalls || 0}-{response.totalCalls || 0}</span> : ""  }
                                    {response.missedCallOn ? <span className="ml-2 text-danger" style={{fontSize: 11}} title="Missed Calls"> | {moment(response.missedCallOn).utcOffset("+05:30").format("Do MMM")}</span> : ""  }
                                </div>
                                }
                            </td>
                            <td className="align-middle" style={{position: "relative"}}>
                                {!!response.customer_remark && <Icon style={{fontSize: 15, cursor:"pointer", position: "absolute", top: 0, right: 0}} onClick={()=> (this.fetchTaskHistory(response.form_response_id, "CUSTOMER_REMARK"))}>info</Icon> }
                                {!!isAddCustomerRemark && isAddCustomerRemark.form_response_id === response.form_response_id ? 
                                    <input value={isAddCustomerRemark.customer_remark} onChange={(e) => { isAddCustomerRemark.customer_remark = e.target.value; this.setState({ isAddCustomerRemark }) }}  onKeyPress={e => { if(e.key === 'Enter') {e.preventDefault(); this.saveCustomerRemark()}  }} />
                                    :
                                    <span className="d-block">{response.customer_remark}</span>
                                }
                                <Icon className="border text-muted clickable shadow-sm" style={{position: "absolute", right: 0, bottom:0, fontSize: 18 }} onClick={() => this.setState({ isAddCustomerRemark: isAddCustomerRemark ? null : { form_response_id: response.form_response_id } })}>add</Icon>
                            </td>
                            <td className="align-middle">
                                <UserCard data={response.User} showProfilePic={false} />
                            </td>
                            <td className="align-middle" style={{whiteSpace: "pre"}}>
                                {response.modified_on && response.created_on !== response.modified_on && moment(response.modified_on).utcOffset("+05:30").format("Do MMM'YY[\n]HH:mm")}
                            </td>
                            <td className="align-middle" title={response.status_comment} style={{position: "relative"}}>
                                <span className="d-block">{truncateString(response.status_comment, 45) || "-"}</span>
                                {!!response.status_comment && !!taskReasons[response.status_comment.toLowerCase()] && <span className="small d-block">{taskReasons[response.status_comment.toLowerCase()]}</span>}
                                {!!response.remark && <span className="small d-block">({response.remark})</span>}
                                {!!response.status_comment && <Icon style={{fontSize: 15, cursor:"pointer", position: "absolute", top: 0, right: 0}} onClick={()=> (this.fetchTaskHistory(response.form_response_id, "STATUS_COMMENT"))}>info</Icon> }
                                {/* {response.sub_status == "1" && <span className="text-danger small font-weight-bold">Partial Onboarding</span>} */}
                            </td>
                            {!!activationStatusData && <td>{activationStatusData[response.status2]? activationStatusData[response.status2].label : ""}</td>}
                            <td style={{minWidth: 80}} className="text-center">
                                {!!response.FormResponseMetadata && 
                                <>
                                    {!!response.FormResponseMetadata.payout && <span className="d-block">{"₹ " + response.FormResponseMetadata.payout}</span>}
                                    {!!response.FormResponseMetadata.badge_level && <span className="d-block font-weight-bold" style={{color: CONSTANTS.badgeConfig[response.FormResponseMetadata.badge_level].baseColor}}><img src={require('../../../assets/images/' + CONSTANTS.badgeConfig[response.FormResponseMetadata.badge_level].iconSvgName)} alt='' style={{height:"14px"}} /> {CONSTANTS.badgeConfig[response.FormResponseMetadata.badge_level].name }</span>}
                                </>}
                            </td>
                            {
                                formInputs.map(input => {
                                return (
                                <td className="visible-on-hover-container" style={{position: "relative"}}>
                                    {!!inputData[input.input_id] && !!inputData[input.input_id].isEdit ?
                                    <>
                                        <form style={{}} onSubmit={(e) => this.submitAddMissingDetails(e, response, inputData, input.input_id)}><input name="input_value" placeholder="Enter Value" defaultValue={inputData[input.input_id].value} /></form>
                                        <Icon className="clickable" style={{fontSize: 16}} onClick={() => this.setEditInput(input, response, inputData, false)}>close</Icon>
                                    </>
                                    :
                                    <>
                                        {inputData[input.input_id] && inputData[input.input_id].value ? 
                                        <>
                                            {["VIDEO", "IMAGE", "FILE"].indexOf(input.input_type) === -1 ? 
                                                inputData[input.input_id].value
                                                :
                                                <>
                                                    {input.input_type === "VIDEO" && <Button onClick={() => this.playVideo(inputData[input.input_id].value)} color="link" size="sm">Play Video</Button>}
                                                    {input.input_type === "IMAGE" && <Button onClick={() => this.showImage(inputData[input.input_id].value)} color="link" size="sm">Image</Button>}
                                                    {input.input_type === "FILE" && <Button onClick={() => this.showFile(inputData[input.input_id].value)} color="link" size="sm">File</Button>}
                                                </>
                                            }
                                        </> 
                                        : 
                                        <span>-</span>
                                        }
                                        {["VIDEO", "IMAGE", "FILE"].indexOf(input.input_type) === -1 && [1, 2].indexOf(input.input_id) === -1 && <Icon className="visible-on-hover-item clickable" style={{fontSize: 14, position: "absolute", top: 0, right: 0}} onClick={() => this.setEditInput(input, response, inputData)}>edit</Icon>}
                                    </>
                                    }
                                </td>
                                )
                                })
                                   
                            }
                            {!!selectedTask.formData.audit && <>
                            <td>{CONSTANTS.AUDIT_STATUS[response.audit_status]}</td>
                            {selectedTask.formData.audit.inputs.map(input => {
                                return (
                                <td>{inputData[input.input_id] && inputData[input.input_id].value ? (
                                    input.input_type === "VIDEO" ? <Button onClick={() => this.playVideo(inputData[input.input_id].value)} color="link" size="sm">Play Video</Button> : inputData[input.input_id].value
                                    ) : "-"}
                                </td>
                                )
                            })}
                            </>
                            }
                        </tr>
                        })
                        : 
                        <tr><td colSpan="10"><em>No Data</em></td></tr>
                        }
                    </tbody>
                </Table>
            </div>
            <TablePagination rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={this.state.taskCount} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                onChangePage={ async (e, page) => { this.fetchUserTask(page) } }
                onChangeRowsPerPage={ async (e) => { await this.setState({rowsPerPage : e.target.value }); this.fetchUserTask() }}
            />


            <Modal isOpen={!!isOpenUpdateFormResponse} toggle={() => this.setState({isOpenUpdateFormResponse : false})}>
                <ModalHeader toggle={() => this.setState({isOpenUpdateFormResponse : false})}>Update Task Response</ModalHeader>
                <form onSubmit={(e) => this.formResponseUpdate(e, isOpenUpdateFormResponse)}>
                <ModalBody>
                    {isOpenUpdateFormResponse.status === "VERIFIED" &&
                    <SelectList name="status2" placeholder="Select Activation Goal" value={isOpenUpdateFormResponse.status2} onChange={(e) => {isOpenUpdateFormResponse.status2 = e.target.value; this.setState({isOpenUpdateFormResponse: this.state.isOpenUpdateFormResponse})}}  >
                        { Object.keys( (selectedTask.formData.response_status.status2 || {})).map((status2, i) => <option key={i} value={status2}>{selectedTask.formData.response_status.status2[status2].label}</option> )}
                    </SelectList>
                    }
                    <InputField required={isOpenUpdateFormResponse.status === "REJECTED"} className="mt-3" name="status_comment" placeholder="Status Comment" value={isOpenUpdateFormResponse.status_comment}  onChange={(e) => { isOpenUpdateFormResponse.status_comment = e.target.value; this.setState({ isOpenUpdateFormResponse }) } }  />
                </ModalBody>
                <ModalFooter>
                    <em className="text-muted">{selectedFormResponse.length} Task{selectedFormResponse.length > 1 && "s"} selected</em>{' '}
                    <Button size="sm" color="danger">
                        {isOpenUpdateFormResponse.status === "VERIFIED" && "Verify"}
                        {isOpenUpdateFormResponse.status === "REJECTED" && "Reject"}
                    </Button>
                    <Button size="sm" className="ml-3" onClick={() => this.setState({isOpenUpdateFormResponse : false})}>Cancel</Button>
                </ModalFooter>
                </form>
            </Modal>

            <Modal centered isOpen={historyData.length} toggle={() => this.setState({historyData: []})}>
                <ModalBody>

                        <Row className="border-bottom">
                            {/* <Col xs={1}></Col> */}
                            <Col xs={8}><h6>Comments</h6></Col>
                            <Col xs={4}><h6>Date</h6></Col>
                        </Row>
                        {historyData.length ?
                         historyData.map(info => 
                        <Row>
                            {/* <Col xs={1}></Col> */}
                        <Col xs={8}>
                            <span style={{fontSize: 12, }}>{info.entity_id}</span>
                        </Col>

                        <Col xs={4} style={{fontSize: 12, }}>{moment(info.created_on).utcOffset("+05:30").format("Do MMM`YY[\n]hh:mm A")}</Col>
                    </Row>) : "" }
                </ModalBody>
            </Modal>

            <Modal centered isOpen={!!this.state.videoPlayUrl} toggle={() => this.setState({videoPlayUrl: null})}>
                <ModalBody className="text-center">
                    <video autoPlay={true} controls={true} width={320} src={this.state.videoPlayUrl} />
                </ModalBody>
            </Modal>
            <Modal centered isOpen={!!this.state.showImageUrl} toggle={() => this.setState({showImageUrl: null})}>
                <ModalBody className="text-center">
                    <img alt="" width={360} src={this.state.showImageUrl} />
                </ModalBody>
            </Modal>

            {!!isShowCustomerDetails && <CustomerProfile customer_lead_id={isShowCustomerDetails.customer_lead_id} toggle={() => this.setState({ isShowCustomerDetails: false })} />}

            <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />

            {!!this.state.callCustomerDetails && <OutboundCustomerCallingStatus customerData={this.state.callCustomerDetails.customerData || {}} calling_id={this.state.callCustomerDetails.calling_id} onClose={() => this.setState({callCustomerDetails : null})} />}


        </>
        )
    }




}
export default TaskData;


const VERIFICATION_COLORS = {0: "#ccc", 1: "#00a000", 2: "#f00"}
const truncateString = (str, n) => {
    str = str || "";
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};


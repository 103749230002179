import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, Input } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import {uploadFile} from "../../../actions"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input"
import { ChromePicker } from "react-color";
import ImageUploadFeild from "../../global/form/upload-image";
import CONSTANTS from "../../../constants"
import RichTextEditor from "../../global/form/Richtext";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Switch } from "@material-ui/core";

class JobCreateEdit extends Component {
    constructor(props) {
      super(props);
      this.state = {
        companyList : [],
        bannerFile : null,
        jdVideoFile : null,
        jobDetails : {
          type: "Full Time",
          archive_status: 2
        },
        prev_client_id:null,
        jobRequirements : [],
        displayColorPicker: false,
        clientList: []
      }
    }
    async componentDidMount() {
        await this.fetchCompany()
        this.fetchClients();
        if(this.props.editJob){
            this.setState({
              jobDetails: this.props.editJob,
              prev_client_id: this.props.editJob.client_id || null,
            });
        }
      }
      
    fetchCompany = async () => {
      store.dispatch(setLoader(true))
        try{
            let companyResp = await ApiEndpoints.Employer.fetchEmployer();
            this.setState({companyList : companyResp.data.data});
            if(companyResp.data.data.length > 0){
            this.onChangeDetailsForJob("employer_id", companyResp.data.data[0].employer_id)
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }
    fetchClients = async () => {
      store.dispatch(setLoader(true))
        try{
            let companyResp = await ApiEndpoints.Invoice.fetchClients();
            this.setState({clientList : companyResp.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    onChangeDetailsForJob = (key, value) =>{
        let { jobDetails } = this.state;
        jobDetails[key] = value;
        this.setState({ jobDetails });
    }
    onChangeRequirments = (e) =>{
        let { jobRequirements } = this.state;
        if (e.target.checked) {
          jobRequirements.push(e.target.value)
        }
        else {
          var i = jobRequirements.indexOf(e.target.value)
          jobRequirements.splice(i, 1);
        }
        this.setState({jobRequirements})
    }
    createProject = async (e) =>{
        e.preventDefault();
        store.dispatch(setLoader(true))
        let { jobDetails, jobRequirements, companyList, bannerFile } = this.state;
        try{
          if(!jobDetails.employer_id) throw new Error("Company Required to create Project")
          if(!jobDetails.job_category_id) throw new Error("Project Category Required to create Project")
          if(!jobDetails.client_id) throw new Error("Client details Required to create Project")
          jobDetails.job_category = companyList.filter(obj => obj.employer_id === jobDetails.employer_id)[0].job_type_access || "WALKIN";
          if(bannerFile){
            var bannerResp = await uploadFile(bannerFile, "JOB_DATA");
            jobDetails.banner_key = bannerResp.file_id;
          }
          let jobResp = await ApiEndpoints.Jobs.addJob(jobDetails);
          jobDetails.job_id = jobResp.data.data.job_id;
         
          this.setState({jobDetails})
          await Promise.all(jobRequirements.map(async requirement => {
              var requirementBody = {
              job_id : jobDetails.job_id,
              is_mandatory : 0,
              ...CONSTANTS.JOB_REQUIREMENTS[requirement]
            }
            await ApiEndpoints.Jobs.addRequirement(requirementBody)
          }))
          if(this.props.onSuccess) await this.props.onSuccess();
          store.dispatch(setSuccess({show: true, message: "Project created successfully"}))
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    editProject = async (e) => {
        e.preventDefault();
        store.dispatch(setLoader(true))
        let { jobDetails, bannerFile, prev_client_id } = this.state;
        try{
          if(!jobDetails.employer_id) throw new Error("Company Required to create Project")
          if(!jobDetails.job_category_id) throw new Error("Project Category Required to create Project")
          if(!jobDetails.client_id) throw new Error("Client details Required to create Project")
            let editJobBody = {
              job_id: jobDetails.job_id,
              job_category_id: jobDetails.job_category_id,
              client_id: jobDetails.client_id,
              prev_client_id:prev_client_id,
              title: jobDetails.title,
              consumer_type: jobDetails.consumer_type,
              tag_line: jobDetails.tag_line,
              tags: jobDetails.tags,        
              rewards: jobDetails.rewards,
              theme_color: jobDetails.theme_color,
              // max_salary: jobDetails.max_salary,
              slack_channel: jobDetails.slack_channel,
              slug_name: jobDetails.slug_name,
              archive_status: jobDetails.archive_status,
              archive_message: jobDetails.archive_message,
              is_recommended: jobDetails.is_recommended ? 1 : 0,
              drr_count: jobDetails.drr_count,
              kpi_ratio: jobDetails.kpi_ratio
            }
            if(bannerFile){
              var bannerResp = await uploadFile(bannerFile, "JOB_DATA");
              editJobBody.banner_key = bannerResp.file_id;
            }
            await ApiEndpoints.Jobs.jobEdit(editJobBody);
            if(this.props.onSuccess) await this.props.onSuccess();
            store.dispatch(setSuccess({show: true, message: "Project details updated successfully"}))
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
          }
          store.dispatch(setLoader(false))

    }

    render(){

        let { companyList, jobDetails, clientList} = this.state;
        let { jobCategoryList } = this.props;
        var userRole = store.getState().user.UserRole
        var isAdmin = !!userRole.filter(role => role.role_id === 1).length;

        return(
        <Row>
            <Col>
              <form onSubmit={this.props.editJob ? this.editProject : this.createProject}>
                <Row>
                  <Col xs={6}>
                    <SelectList search={true} id="company_list" disabled={!!this.props.editJob} value={jobDetails.employer_id} onChange={(e) => this.onChangeDetailsForJob("employer_id", e.target.value)}>
                      {
                        companyList.map((item, i)=> 
                          <option key={i} value={item.employer_id}> {item.employer_name}</option>
                        )
                      }
                    </SelectList>
                  </Col>
                  <Col xs={6}>
                    <SelectList id="category_list"  value={jobDetails.job_category_id} onChange={(e) => this.onChangeDetailsForJob("job_category_id", e.target.value)}>
                      {
                        jobCategoryList.map((cat, i)=> 
                          <option key={i} value={cat.job_category_id}> {cat.title}</option>
                        )
                      }
                    </SelectList>
                  </Col>
                </Row>
                {/* <Row className="mt-3">
                  <Col xs={8}>
                      <div className="switch-toggle" style={{fontSize: 12}}>
                      {
                          Object.keys(CONSTANTS.ARCHIVE_STATUS).map((key, i) => 
                              <div key={i} className={jobDetails.archive_status === CONSTANTS.ARCHIVE_STATUS[key] ? "active" : ""} onClick={() => this.onChangeDetailsForJob("archive_status", CONSTANTS.ARCHIVE_STATUS[key]) }>{key}</div>
                          )
                      }
                      </div>
                  </Col>
                </Row>
                {jobDetails.archive_status === 1 && <InputFeild className="my-2 p-0" style={{fontSize: 12}} placeholder={"Enter the Hold message"} value={jobDetails.archive_message || ""} onChange={(e) => this.onChangeDetailsForJob("archive_message", e.target.value) } />} */}
               <Row>
               
                  <Col xs={6}>
                    <SelectList className="mt-3" id="category_list" value={jobDetails.client_id} onChange={(e) => this.onChangeDetailsForJob("client_id", e.target.value)}>
                      {
                        clientList.map((cat, i)=> 
                          <option key={i} value={cat.client_id}> {cat.client_name}</option>
                        )
                      }
                    </SelectList>
                    <input className="mt-4 border-0 w-50" type="color" value={jobDetails.theme_color} onChange={(e)=>{this.onChangeDetailsForJob("theme_color",e.target.value )}}  />
                  </Col>
                  <Col>
                    <ImageUploadFeild id="upload_banner_img" src={jobDetails.banner_key} className="mt-2" label="Upload Banner Image" onChange={(file) => this.setState({bannerFile : file})}/>
                    {/* <Button size="sm" type="button" id="color_picker" className="mt-3" onClick={() => this.setState({ displayColorPicker: !displayColorPicker })} style={{backgroundColor: (jobDetails.theme_color || "#000")}}>Pick Color</Button>
                   
                    { displayColorPicker ? <div style={{ position: 'absolute', zIndex: '2'}}>
                      <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}} id="close_color_picker"
                        onClick={() => this.setState({ displayColorPicker: false })}></div>
                      <ChromePicker id="color_picker_tool" color={jobDetails.theme_color || "#000"} onChange={(color) => this.onChangeDetailsForJob("theme_color", color.hex)} />
                    </div> : null } */}
                  </Col>
               </Row>

                <Row className="mt-3">
                  <Col>
                    <InputFeild id="project_title" required  placeholder="Project Title" value={jobDetails.title} onChange={(e)=>{this.onChangeDetailsForJob("title",e.target.value )}}/>
                  </Col>
                  <Col xs={3}>
                    <SelectList className="" id="consumer_type" required placeholder="Consumer Type" value={jobDetails.consumer_type}  onChange={(e) => this.onChangeDetailsForJob("consumer_type", e.target.value)}>
                        <option value={1}> {"B2B"}</option>
                        <option value={2}> {"B2C"}</option>
                        <option value={3}> {"Data Labeling"}</option>
                    </SelectList>
                  </Col>
                  <Col xs={3}>
                      <SelectList multiple="checkbox" className="" id="tags" placeholder="Project Tags" value={jobDetails.tags ? jobDetails.tags.split(";;") : []}  onChange={(e) => this.onChangeDetailsForJob("tags", e.target.value.join(";;"))}>
                          {
                            Object.keys(CONSTANTS.JOB_TAGS).map(k => <option key={k} value={k}>{CONSTANTS.JOB_TAGS[k]}</option>)
                          }
                      </SelectList>
                  </Col>
                </Row>
                
                {/* <Row className="mt-2 align-items-center">
                    <Col>
                      <InputFeild id="maximum_earn_oportunity" className="mt-2" placeholder="Maximum Earn Opportunity" value={jobDetails.max_salary}  onChange={(e)=>{this.onChangeDetailsForJob("max_salary",e.target.value )}}/>
                    </Col>
                    
                </Row> */}
                
                <InputFeild id="perks" className="mt-2" placeholder="Perks" value={jobDetails.rewards}  onChange={(e)=>{this.onChangeDetailsForJob("rewards",e.target.value )}}/>
                
                <InputFeild id="project_tagline" required className="mt-2" placeholder="Project Tagline" value={jobDetails.tag_line} onChange={(e)=>{this.onChangeDetailsForJob("tag_line",e.target.value )}}/>
                
                <Row>
                    <Col>
                      <InputFeild id="slack_channel" className="mt-2" placeholder="Slack Report Channel (#proj-project)" value={jobDetails.slack_channel} onChange={(e)=>{this.onChangeDetailsForJob("slack_channel",e.target.value )}}/>
                    </Col>
                    <Col>
                      <InputFeild id="slug_name" required className="mt-2" placeholder="Slug Name (User friendly name of project)" value={jobDetails.slug_name} onChange={(e)=>{this.onChangeDetailsForJob("slug_name",e.target.value )}}/>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                      <InputFeild id="drr_count" type="number"  className="mt-2" placeholder="DRR Count (Leave 0 if not required)" value={jobDetails.drr_count} onChange={(e)=>{this.onChangeDetailsForJob("drr_count",e.target.value )}}/>
                    </Col>
                    <Col>
                      {jobDetails.kpi_ratio !== null && <InputFeild id="api_ratio" type="number" required className="mt-2" placeholder="KPI Ratio (Percentage)" value={jobDetails.kpi_ratio} onChange={(e)=>{this.onChangeDetailsForJob("kpi_ratio",e.target.value )}}/>}
                    </Col>
                </Row>

                {isAdmin && <div className="my-3">
                  <span style={{fontSize: 14}}>Is Recommended to all Partners?</span> <Switch size="small" checked={!!jobDetails.is_recommended} onChange={(e) =>  this.onChangeDetailsForJob("is_recommended", e.target.checked) } />
                </div>}


                {/* <InputFeild id="wa_group_link" className="mt-2" placeholder="Whatsapp Group Link" value={jobDetails.wa_group_link} onChange={(e)=>{this.onChangeDetailsForJob("wa_group_link",e.target.value )}}/> */}
                
                {/* <Row className="my-3">
                  <Col>
                    <RichTextEditor id="project_description" placeholder="Project Description" value={jobDetails.description || ""} onChange={(value)=>{this.onChangeDetailsForJob("description", value )}}/>
                  </Col>
                </Row> */}
                
                {/* <p className="text-muted mt-3">Job Requirements</p>
                <Row className="align-items-center ml-1" style={{fontSize: "12px"}}>
                  {
                    CONSTANTS.JOB_REQUIREMENTS.map((item, i)=> 
                      <Col key={i} xs={6} className="mb-2">   
                        <Input id={"job_requirement_" + i} type="checkbox" value={i}  onChange={this.onChangeRequirments}/> 
                        <span className="pl-3">{item.eligibility_value}</span>
                      </Col>
                    )
                  }
                  </Row> */}
                  <Button id="project_create_submit"  type="submit" size="24px" block className="buttonred">{this.props.editJob ? "Edit Project Details" : "Create Project"}</Button>
              </form>

            </Col>
        </Row>

        );
    }
}
export default JobCreateEdit;

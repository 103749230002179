var TICKET_CONSTANTS = {
  REPLY_TEMPLATES: [
    {
      label: "General Messages",
      type: "GENERAL",
      texts: [
        {
          label: "PickMyWork Intro",
          text: [
            "PickMyWork is an earning platform where you can make money on a weekly basis. Currently, we have over 45+ projects on PickMyWork. These projects include Loans, Credit Cards, Demat Accounts, Bank Accounts and more.\nWatch training videos on app and start earning.",
          ],
        },
        {
          label: "Closure Statement",
          text: [
            "If you have further questions, please feel free to ask. Your satisfaction is important to us!",
            "Hope its resolved your query, Your satisfaction is important to us!",
          ],
        },
        {
          label: "Call Attempt Failure",
          text: [
            "We tried calling you, but it seems you were busy at that time.\nNo worries! Let us know the good time to connect and resolve your query on priority.",
          ],
        },
        {
          label: "PAN/Aadhaar Add",
          text: [
            "To Add/Update your personal details in the PickMyWork application, follow these steps:\n\nOpen the PickMyWork application on your device.\nTap on your profile picture, which can be found in the top section of the app.\nNavigate to the 'Personal Details' section.\nIn this section, you will find the option to add or update your details.\n\nHope its resolved your query.",
          ],
        },
        {
          label: "Payout Initiated",
          text: [
            "The payment has been initiated. It may take a few hours for the payment to reflect in your bank account. The payment will be transferred to your account by the end of the day.",
          ],
        },
        {
          label: "Thursday Payout Waiting",
          text: [
            "Dear, We process payouts every thursday between 2-4PM, it may take few hours to reflect at your end, please wait for end of the day.\n\n Thanks for understanding",
          ],
        },
        {
          label: "Project on HOLD",
          text: [
            "Hello Partner\nSorry for your inconvenience. Project is on HOLD for some technical upgradation. Don't worry, your added leads are under verification. And your pending lead's status will next update on the mentioned date in the Task Report.",
          ],
        },
        {
          label: "Less Project/Need Help/Please Call",
          text: [
            "Please let me know how I can assist you. I am here to provide any support or guidance you may need to ensure your goals are achieved efficiently and effectively.",
          ],
        },
      ],
    },
    {
      label: "Leads related content",
      type: "LEADS",
      texts: [
        {
          label: "Pending Lead - Next report Update",
          text: [
            "Hi Partner,\nPlease check the details of the upcoming verification date in your project’s [View Report] Screen. “Pending Lead status will next update on…” . \nIf you have any further questions, please feel free to ask. Your satisfaction is important to us!",
          ],
        },
        {
          label: "Pending Lead - Delay in Report",
          text: [
            "Hi Partner,\nSorry for the inconvenience caused. Your pending lead’s verification is not done due to some delay from the client side. Please wait until the next verification report.\nPlease check the details of the upcoming verification date in your project’s [View Report] Screen. “Pending Lead status will next update on…”. \nIf you have any further questions, please feel free to ask. Your satisfaction is important to us!",
          ],
        },
        {
          label: "When Rejected",
          text: [
            "Hi Partner,\nSorry for the inconvenience caused but your task is rejected due to [Rejection Reason]. If you have any further questions, please feel free to ask. Your satisfaction is important to us!",
          ],
        },
        {
          label: "Lead Expired 1",
          text: [
            `Hi, Thank you for your efforts. Unfortunately, your lead has expired because it couldn't be tracked on the client side. Here are some common reasons for this issue:
1. Background apps may have been open during onboarding on the customer's mobile device.
2. The onboarding process was not completed in one attempt (e.g., webpage reloaded).
3. The process might have been interrupted by incoming calls or messages.
4. Multiple onboarding attempts may have occurred on the same device (only one account per device is allowed).
5. The customer may have already joined the project/bank through a promotional campaign.
Please keep these points in mind for future onboarding. Your ongoing dedication to your tasks is greatly appreciated!
`,
          ],
        },
        {
          label: "Lead Expired 2",
          text: [
            `We would like to kindly remind you that payouts are provided only for verified leads, as stated in our app.
We appreciate your ongoing efforts with PickMyWork and want to assure you that we are committed to improving our services for all our partners. Thank you for your understanding!`,
          ],
        },
      ],
    },
    // {
    //     label: "Recharge",
    //     type: "RECHARGE",
    //     texts: [
    //         {
    //             label: "Payment Not Done",
    //             text: [
    //                 `Hi, The payment is not received from your end.
    //                 If your amount is deducted, It will be auto refunded into your account within 3-4 working days from your bank end.`
    //             ]
    //         },
    //         {
    //             label: "Team Checking",
    //             text: [
    //                 `Don't worry, Please wait!
    //                 Your details has been forwarded to technical team. It may take upto 24 hours.
    //                 `
    //             ]
    //         },
    //         {
    //             label: "Refund Confirm",
    //             text: [
    //                 `Hi Partner, Your refund has already initiated from our end.
    //                 It may take upto 3-4 working days to reflect into your deducted bank account.
    //                 `
    //             ]
    //         },
    //         {
    //             label: "Refund Issue",
    //             text: [
    //                 `Your refund already processed from our end and it must be reflected into your debited bank account within 3-4 working days after transaction.
    //                 If you have any issue with this, please share your bank statement of sourced bank account so we we can consult it with our payment service.`
    //             ]
    //         },
    //         {
    //             label: "Wrong Number Recharged",
    //             text: [
    //                 `Hi, Recharge can only be refunded in case of a failure or issue related to the service.
    //                 Since the recharge was successful, there is no option for a refund in this case.
    //                 Please be more careful next time to avoid such mistakes.`
    //             ]
    //         }
    //     ]
    // },
    {
      label: "Other",
      type: "OTHER",
      texts: [
        {
          label: "GPay ID Unavailable",
          text: [
            `Due to technical limitations, we are not working at your location. So, we are unable to process your GPay ID request at this time. 
We apologize for the inconvenience caused. We have multiple other projects for you to work on.`,
          ],
        },
        {
          label: "Transfer to Tech Team",
          text: [
            `Hi Partner,
Sorry for the inconvenience caused to you. We are discussing it with technical team, Please wait for 24 hrs to get this issue resolved.
If you have any further questions, please feel free to ask.
Your satisfaction is important to us!
                        `,
          ],
        },
        {
          label: "Account Deletion",
          text: [
            `Hi Partner,
Sorry for the inconvenience caused. we’d love to understand your reason for leaving. Your feedback is valuable, and we want to ensure we’re providing the best experience for our users. 
If you’re facing any issues or concerns, we’d be happy to assist you and find a solution that works for you. Let us know how we can help!`,
          ],
        },
      ],
    },
  ],
};

export default TICKET_CONSTANTS;

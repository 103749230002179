import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col, Modal, ModalBody } from "reactstrap/lib";
import { Button } from 'reactstrap';
import InputFeild from "../../global/form/input"
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import CONSTANTS from "../../../constants/index"
import { Icon, Chip } from "@material-ui/core";
import SelectList from "../../global/form/select";
import moment from "moment";
import DatePicker from "react-datepicker";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg"
import { groupArrayOnKey } from "../../../actions";
import JobInformation from "../project/job_information";
import UserBadgeHistory from "./user_badge_history";


class ProfileBasicDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileDetail : {details:{}},
            isEditProfile : false,
            deviceInfo: {},
            isShowProfileImage: false,
            projectVisible: [],
            referralData : [],
            isShowJobDetails: false,
            isShowBadgeHistory: false
        }
    }

    componentDidMount(){
        this.getDeviceInfo();
        // this.getvisibleProject();
        this.fetchReferral();
    }

   
    getDeviceInfo = async () => {
        try{
            var body = { user_id : this.props.user_id }

            let resp = await ApiEndpoints.UserProfile.fetchDeviceInfo(body)
            let deviceInfo = resp.data.data[0] || {};
           
            this.setState({ deviceInfo })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
      }

      onChangeProfile =  (key,value, isDetail) => {
        let { profileDetail } = this.state;
        if(isDetail) 
            profileDetail.details[key] = value;
        else
            profileDetail[key] = value;
        this.setState({profileDetail})
      }

      profileUpdate = async () => {
        store.dispatch(setLoader(true))
        let {profileDetail} = this.state;
        try{
          var body = {
            ...profileDetail,
            ...profileDetail.details
          }
          delete body.mobile;
          delete body.details;
          await ApiEndpoints.UserProfile.updateProfile(body );
          this.setState({isEditProfile : false});
          store.dispatch(setSuccess({show: true, message: "Profile Edited Successfully!"}));
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    // getvisibleProject = async () => {
    //     try{
    //         var body = { user_id : this.props.user_id }

    //         let resp = await ApiEndpoints.UserProfile.userVisibleProject(body)
    //         let projectVisible = resp.data.data || [];
    //         projectVisible = groupArrayOnKey(projectVisible, "job_category_id");
    //         this.setState({ projectVisible })

    //     }
    //     catch(e){
    //         store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
    //     }
    //   }

      fetchReferral = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { used_by : this.props.user_id}
            let resp = await ApiEndpoints.Referral.fetchReferralDetails(body)
            let referralData = resp.data.data
            this.setState({ referralData })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    render() {
        let { isEditProfile, deviceInfo, projectVisible,referralData, isShowJobDetails, isShowBadgeHistory } = this.state;
        let { profileDetail } = this.props;
        if(isShowBadgeHistory) return <UserBadgeHistory {...this.props} onBack={() => this.setState({ isShowBadgeHistory: false })} />
        return (<>
                <div className="" style={{fontSize: 14}}>
                            {/* <Button className="buttonred py-0 " style={{fontSize : 12 , cursor : "pointer", position: "absolute", top: 5, right: 5}} onClick={() => this.setState({isEditProfile : true})}>Edit</Button> */}
                            <Row className="mt-3 border-bottom">
                                <Col xs={3} className="mb-1">
                                    {profileDetail.details && profileDetail.details.profile_picture_url ? 
                                    <>
                                        {this.state.isShowProfileImage ? 
                                        <img style={{width: "100%"}} src= {(profileDetail.details && profileDetail.details.profile_picture_url) || "/images/dummy_profile.jpg"}  alt="Profile"></img>
                                        : 
                                        <div style={{width: "100%", height: 100, cursor: "pointer"}} className="d-flex bg-light align-items-center justify-content-center" onClick={() => this.setState({isShowProfileImage: true})}>
                                            <Icon className="align-middle">visibility</Icon>
                                        </div>
                                        }
                                    </>
                                    :
                                    <img style={{width: "100%"}} src= {"/images/dummy_profile.jpg"}  alt="Profile"></img>
                                    }
                                </Col>
                                <Col xs={9} className="mb-2">
                                    <span className="text-dark d-block" style={{fontSize: 14}}><Icon style={{fontSize: 14}}>badge</Icon> PMW{profileDetail.id}
                                    <div className="d-inline-block border ml-3 clickable" style={{borderRadius: 20, padding: "0 5px 2px 5px", backgroundColor: CONSTANTS.badgeConfig[profileDetail.details.badge_level]?.secondaryColor+20}} onClick={() => this.setState({ isShowBadgeHistory: true })}>
                                        <img title={CONSTANTS.badgeConfig[profileDetail.details.badge_level]?.iconSvgName.replace(".svg","")} src={profileDetail.details.badge_level >=0 ? require('../../../assets/images/'+CONSTANTS.badgeConfig[profileDetail.details.badge_level].iconSvgName):""} alt='' style={{height:"18px"}} />
                                        <span className="small font-weight-bold" style={{color: CONSTANTS.badgeConfig[profileDetail.details.badge_level]?.baseColor}}>{" "}{CONSTANTS.badgeConfig[profileDetail.details.badge_level]?.name}</span>
                                    </div>
                                    </span>
                                    {isEditProfile ?
                                    <InputFeild placeholder="Name" value={profileDetail.first_name} onChange={(e) => this.onChangeProfile("first_name", e.target.value, false)} />
                                    :
                                    <span className="text-dark d-block" style={{fontSize: 15}}><Icon style={{fontSize: 14}}>account_box</Icon> {profileDetail.first_name}</span>
                                        }
                                        
                                    {isEditProfile ?
                                    <InputFeild placeholder="Email" value={profileDetail.email} onChange={(e) => this.onChangeProfile("email", e.target.value, false)} />
                                    :
                                    <span className="text-muted d-block" style={{fontSize: 14}}><Icon style={{fontSize: 14}}>email</Icon> {profileDetail.email}</span>
                                    }
                                    
                                    {isEditProfile ?
                                    <InputFeild placeholder="Whatsapp Number" defaultValue={profileDetail.details.whatsapp_no && "******"+profileDetail.details.whatsapp_no.toString().substr(-4)} onChange={(e) => this.onChangeProfile("whatsapp_no", e.target.value, true)} />
                                    :
                                    <span className="text-muted d-block" style={{fontSize: 14}}> <img src={WhatsappIcon} alt="" style={{width: 18, marginTop : -11, marginLeft: -2}}/> {profileDetail.details.whatsapp_no && "******"+profileDetail.details.whatsapp_no.toString().substr(-4)}</span>
                                    }
                                </Col>
                            </Row>
                            

                            <div>
                                <Row>
                                    <Col className="border-right">
                                        <Row className="">
                                            <Col>
                                                <span>Referred by </span>
                                                {referralData.length ?
                                                <div style={{fontSize:13}}>
                                                    <span>{referralData[0].user.first_name}</span><br/>
                                                    <span>PMW{referralData[0].user.id}</span>
                                                </div>
                                                : 
                                                <em className="m-0 text-secondary d-block"> - </em>
                                                }
                                                <span className="mt-2 small d-block">User Type</span>
                                                {isEditProfile ?
                                                    <div>
                                                        <Chip className="ml-1 mb-1" size="small" onClick={() => this.onChangeProfile("consumer_type", 1, true)} color={profileDetail.details.consumer_type !== 2 ? "secondary" : ""} label={"Individual Partner"} />
                                                        <Chip className="ml-1 mb-1" size="small" onClick={() => this.onChangeProfile("consumer_type", 2, true)} color={profileDetail.details.consumer_type === 2 ? "secondary" : ""} label={"Shop Owner"} />
                                                    </div>
                                                    :
                                                    <span>{profileDetail.details.consumer_type === 2 ? "Shop Owner" : "Individual Partner"}</span>
                                                }
                                            </Col>
                                        </Row>
                                
                                        <span className="mt-2 small d-block">Permanent Address</span>
                                        {isEditProfile ?
                                        <InputFeild placeholder="Permanent Address" value={profileDetail.details.permanent_address} onChange={(e) => this.onChangeProfile("permanent_address", e.target.value, true)} />
                                        :
                                        <span className="" style={{fontSize: 14}}>{profileDetail.details.permanent_address || "-"}</span>
                                        }

                                        <Row className="mt-2">
                                            <Col>
                                            <span className="mt-2 small d-block">Date of Birth</span>
                                                {isEditProfile ?
                                                    <DatePicker
                                                        selected={moment(profileDetail.details.dob || new Date()).toDate()}
                                                        onChange={date => this.onChangeProfile("dob", date, true)}
                                                        className=""
                                                        id="partner_dob"
                                                        dateFormat='dd-MMM-yyyy'
                                                        placeholderText="Select DOB"

                                                    />
                                                    :
                                                    <span>{profileDetail.details.dob ? moment(profileDetail.details.dob).utcOffset("+05:30").format("Do MMM YYYY") : "-"}</span>
                                                }
                                            </Col>
                                               
                                            </Row>
                                            <Row className="mt-2">
                                                {/* <Col>
                                                    <span className="mt-2 small d-block">Availability</span>
                                                    {isEditProfile ?
                                                        <div>
                                                            {Object.keys(CONSTANTS.PROFILE.AVAILABILITY).map(key =>
                                                                <Chip className="ml-1 mb-1" size="small" onClick={() => this.onChangeProfile("availability", key, true)} color={profileDetail.details.availability == key ? "secondary" : ""} label={CONSTANTS.PROFILE.AVAILABILITY[key]} />
                                                            )}
                                                        </div>
                                                        :
                                                        <span>{CONSTANTS.PROFILE.AVAILABILITY[profileDetail.details.availability] || "-"}</span>
                                                    }
                                                </Col> */}
                                                <Col>
                                                    <span className="mt-2 small d-block">Employment & Experience (months)</span>
                                                    {isEditProfile ?
                                                        <div>
                                                            {Object.keys(CONSTANTS.PROFILE.EMPLOYEMENT_STATUS).map(key =>
                                                                <Chip className="ml-1 mb-1" size="small" onClick={() => this.onChangeProfile("employment_status", key, true)} color={profileDetail.details.employment_status == key ? "secondary" : ""} label={CONSTANTS.PROFILE.EMPLOYEMENT_STATUS[key]} />
                                                            )}
                                                        </div>
                                                        :
                                                        <span>{CONSTANTS.PROFILE.EMPLOYEMENT_STATUS[profileDetail.details.employment_status] || "-"} {profileDetail.details.work_experience ? "("+ profileDetail.details.work_experience + ")" : ""}</span>
                                                    }
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col>
                                                    <span className="mt-2 small d-block">App Info</span>   
                                                    <span>{`
                                                        Version: ${profileDetail.details && profileDetail.details.current_app_version}, 
                                                        Model: ${deviceInfo.manufacturer}-${deviceInfo.model_name}, 
                                                        Android OS: ${deviceInfo.os_version}, 
                                                        Network: ${deviceInfo.network_carrier}
                                                        ${!!deviceInfo.is_blocked ? (", Device Blocked"+ deviceInfo.is_blocked) : ""}
                                                    `}</span>
                                                </Col>
                                            </Row>
                                            <div className="text-right small mt-2 text-danger">
                                                <span className="d-block">App Download: <br />{profileDetail.details && moment(profileDetail.details.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</span>
                                                {!!profileDetail.details.uninstalled_on && <span className="px-1 border shadow-sm ml-2" style={{borderRadius: 30, fontSize: 10,color:"#f1061c",fontWeight: 650}}>Uninstalled-{moment( profileDetail.details.uninstalled_on || moment().toDate() ).utcOffset("+05:30").format("Do MMM'YY")}</span>}

                                            </div>
                                           
                                        </Col>

                                       
                            </Row>
                        </div>

                        <Modal centered size="lg" isOpen={!!isShowJobDetails} toggle={() => this.setState({ isShowJobDetails: null })}>
                            <ModalBody  toggle={() => this.setState({ isShowJobDetails: null })}>
                                {!!isShowJobDetails && <JobInformation job={isShowJobDetails} isOnlyView={true} />}
                            </ModalBody>
                        </Modal>

            </div>
        </>
        );
    }
}
export default ProfileBasicDetails;


import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader, setSuccess } from "../../../actions/action-creators";
import ConfirmAction from "../../global/confirm-action";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {  Drawer, Icon, TablePagination, TextField } from "@material-ui/core";
import moment from "moment";
import InvoiceCreate from "./invoice_create";
import SelectList from "../../global/form/select";
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { copyCodeToClipboard } from "../../../actions";

class InvoiceHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientList: [],
            invoiceList: [],
            currentUserPage: 0,
            rowsPerPage: 20,
            isFilters: {  },
            isCreateInvoice: false,
            isOpenMarkInvoicePaid: false
        };
    }


    componentDidMount(){
        var userRole = store.getState().user.UserRole
        var isAdmin = !!userRole.filter(role => role.role_id === 1).length;
        try{
        if(!isAdmin) throw new Error("Ahh! You don't have access")
        this.fetchClients();
        this.fetchInvoices();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    fetchClients = async() => {
        try{
            let body = {};
            let apiData = await ApiEndpoints.Invoice.fetchClients(body);
            let clientList = apiData.data.data;
            this.setState({ clientList })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    fetchInvoices = async(page = 0) => {
        store.dispatch(setLoader(true))
        try{
            var body = { 
                limit: this.state.rowsPerPage ,
                offset : page * this.state.rowsPerPage
            };
            let apiData = await ApiEndpoints.Invoice.fetchInvoice(body);
            let invoiceList = apiData.data.data;
            let allInvoiceNumbers = {}
            let creditNoteRefInvoiceIds = [];
            invoiceList = invoiceList.map(invoice => {
                invoice.status_text = this.getInvoiceStatus(invoice);

                allInvoiceNumbers[invoice.invoice_id] = invoice.serial_number;
                if(invoice.credit_note_invoice_id) creditNoteRefInvoiceIds.push(invoice.credit_note_invoice_id);
                return invoice;
            });

            invoiceList = invoiceList.map(invoice => {
                if(invoice.credit_note_invoice_id) invoice.referenceCreditNoteId = allInvoiceNumbers[invoice.credit_note_invoice_id];
                if(creditNoteRefInvoiceIds.includes(invoice.invoice_id)) invoice.isCreditNoteRaised = true;
                return invoice;
            });

            this.setState({ invoiceList, currentUserPage: page })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    sendInvoiceEmail = async(invoice) => {
        store.dispatch(setLoader(true))
        try{
            var body = { 
                invoice_id: invoice.invoice_id,
                sendMail: true
            };
            await ApiEndpoints.Invoice.sendInvoiceMail(body);
            store.dispatch(setSuccess({show: true, message: "Email sent successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    markPaidInvoice = async(invoice) => {
        store.dispatch(setLoader(true));
        let { invoiceList } = this.state;
        try{
            var body = { 
                invoice_id: invoice.invoice_id,
                payment_message: invoice.payment_message,
                total_received_amount: (invoice.total_received_amount || 0) * 100,
                payment_date:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                status: 20
            };
            await ApiEndpoints.Invoice.markPaidInvoice(body);
            invoiceList = invoiceList.map(obj => {
                if(obj.invoice_id === invoice.invoice_id){
                    obj.status = 20;
                    obj.total_received_amount = body.total_received_amount;
                    obj.payment_message = body.payment_message;
                    obj.status_text = this.getInvoiceStatus(obj);
                    obj.payment_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                    return obj;
                } else return obj;
            });
            this.setState({ invoiceList })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.setState({ isOpenMarkInvoicePaid: false });
        store.dispatch(setLoader(false))
    }

    generateIRN = async(invoice) => {
        store.dispatch(setLoader(true))
        try{
            var body = { invoice_id: invoice.invoice_id };
            await ApiEndpoints.Invoice.generateInvoiceIRN(body);
            this.fetchInvoices();
            store.dispatch(setSuccess({show: true, message: "Generated IRN successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    cancelInvoice = async(invoice) => {
        store.dispatch(setLoader(true))
        try{
            //Raise Credit Note if invoice cancel after 24hour GST window 
            if(moment().diff(moment(invoice.created_on), "minutes") > 1440){
                let invoiceData = await ApiEndpoints.Invoice.fetchInvoiceById({ invoice_id: invoice.invoice_id });
                invoiceData = invoiceData.data.data[0];

                this.setState({isCreateInvoice : { 
                    invoiceData: {
                        ...invoiceData, 
                        taxable_amount: invoiceData.taxable_amount / 100,
                        expiry_date: null,
                        items: invoiceData.InvoiceItems.map(item => {
                            item.isActive = true;
                            item.rate_per_item = item.rate_per_item / 100;
                            item.amount = item.amount / 100;
                            return item;
                        }),
                        credit_note_invoice_id: invoiceData.invoice_id,
                        creditNoteInvoice: invoiceData,
                        isCreditNote: true,
                        invoice_id: null,
                        serial_number: null
                    },
                    isPreview: true
                }
                })
                return store.dispatch(setLoader(false))
            } else {
                var body = { invoice_id: invoice.invoice_id };
                await ApiEndpoints.Invoice.cancelInvoice(body);
                this.fetchInvoices();
                store.dispatch(setSuccess({show: true, message: "Invoice cancelled successfully"}))
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    downloadInvoice = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileBody = {file_id : fileId}
            let urlResp = await ApiEndpoints.Files.getFileID(fileBody);
            let fileUrl = urlResp.data.data[0] ? urlResp.data.data[0].url : null;
            const link = document.createElement('a');
            link.href = fileUrl;
            link.target= "_blank";
            link.download = true;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    closeCreateInvoice = (isRefresh) => {
        if(isRefresh) this.fetchInvoices();
        this.setState({ isCreateInvoice: false });
    }

    onChangeFilter = (k, v) => {
        let { isFilters } = this.state;
        isFilters[k] = v;
        this.setState({ isFilters, currentUserPage: 0 });
    }

    openMarkInvoicePaidModal = (invoice) =>
    {
        this.setState({isOpenMarkInvoicePaid: { invoice_id: invoice.invoice_id, total_received_amount: invoice.total_amount / 100 }});
    }

  render() {
    let { clientList, invoiceList, isFilters, isOpenMarkInvoicePaid} = this.state;
    if(isFilters.client_id) invoiceList = invoiceList.filter(invoice => invoice.client_id == isFilters.client_id)
    if(isFilters.status_text) invoiceList = invoiceList.filter(invoice => invoice.status_text && invoice.status_text.props.children.includes(isFilters.status_text))
    if(isFilters.type) invoiceList = invoiceList.filter(invoice =>  isFilters.type === "CN" ? !!invoice.credit_note_invoice_id : !invoice.credit_note_invoice_id);
    return (
    <div className="">
      
        <Row className={"py-3 border-bottom align-items-end"}>
            <Col xs={10}>
                <strong className="large">Invoice & Credit Note</strong>
            </Col>  
            <Col xs={2} className="text-right">
                <Button outline color="danger" onClick={() => this.setState({ isCreateInvoice: true })}>Generate Invoice</Button>
            </Col>
        </Row>

        <Row className="mt-3 align-items-center">
            <Col xs={3}>
                <SelectList search selectAll="All Clients" placeholder="Filter by Clients" value={isFilters.client_id} onChange={(e) => this.onChangeFilter("client_id", e.target.value)}>
                    {clientList.map(client => <option key={client.client_id} value={client.client_id}>{client.client_name}</option>)}
                </SelectList>
            </Col>
            <Col xs={3}>
                <SelectList search selectAll="All Documents" placeholder="Type" value={isFilters.type} onChange={(e) => this.onChangeFilter("type", e.target.value)}>
                    <option value="I">Invoice</option>
                    <option value="CN">Credit Note</option>
                </SelectList>
            </Col>
            <Col xs={3}>
                <SelectList search selectAll="All Status" placeholder="Filter by Status" value={isFilters.status_text} onChange={(e) => this.onChangeFilter("status_text", e.target.value)}>
                    <option value={"Overdue!"}><strong className="text-danger">Overdue!</strong></option> 
                    <option value={"Created"}><span className="text-secondary">Created</span></option> 
                    <option value={"Sent"}><span className="text-secondary">Sent</span></option> 
                    <option value={"Paid!"}><span className="text-success">Paid!</span></option> 
                    <option value={"Expired"}><span className="text-warning">Expired</span></option> 
                    <option value={"Cancelled"}><span className="text-warning">Cancelled</span></option> 
                </SelectList>
            </Col>
        </Row>
       
        <Row className="py-2 small my-3 font-weight-bold bg-secondary text-light align-items-center">
            <Col xs={2}><span>Invoice#</span></Col>
            <Col xs={2}><span>Client</span></Col>
            <Col xs={1}><span>Duration</span></Col>
            <Col xs={1}><span>Amount</span></Col>
            <Col xs={1}><span>E-Invoice</span></Col>
            <Col xs={2} className="text-center"><span>Payment Info</span></Col>
            <Col xs={1} className="text-center"><span>Pending Amt</span></Col>
            <Col xs={1}><span>Status</span></Col>
            <Col xs={1}></Col>
        </Row>
        {
        invoiceList.length ? 
        <>
        {
        invoiceList
        .slice(this.state.currentUserPage*this.state.rowsPerPage, this.state.currentUserPage*this.state.rowsPerPage+this.state.rowsPerPage)
        .map((invoice, i) => {
            return <Row key={i} className="border-bottom py-2 my-2 align-items-center" style={{fontSize: 14}} >
                    
                <Col xs={2}>
                    {!!invoice.credit_note_invoice_id && <Icon style={{fontSize: 18, position: "absolute", top: "-7px", left: 0}} title={invoice.referenceCreditNoteId}>copyright</Icon>}
                    <span className="d-block">{invoice.serial_number}</span>
                    <span className="small text-muted">{moment(invoice.created_on).utcOffset("330").format("DD/MM/YYYY")} | {invoice.createdByUser ? invoice.createdByUser.first_name : "-"}</span>
                </Col>
                <Col xs={2}><span>{invoice.Client.client_name}</span></Col>
                <Col xs={1}>
                    <span className="small text-center">
                        {moment(invoice.start_date).utcOffset("330").format("Do MMM'YY")}<br />
                        {moment(invoice.end_date).utcOffset("330").format("Do MMM'YY")}
                    </span>
                </Col>
                <Col xs={1} className=""><span>&#x20B9;{invoice.total_amount/100}</span></Col>
                <Col xs={1}>
                    {!!invoice.irn_no && <span className="clickable" onClick={() => { copyCodeToClipboard(invoice.irn_no); store.dispatch(setSuccess({show: true, message: "IRN Copied"})) }}>IRN ✔</span>}
                </Col>
                <Col xs={2} className={"text-center visible-on-hover-container" }>
                    {!!invoice.total_received_amount && <span className="d-block">&#x20B9;{invoice.total_received_amount/100}</span>}
                    {!!invoice.payment_message && <span className="d-block small">{invoice.payment_message}</span>}
                    <span className="small text-muted">{invoice.payment_date && moment(invoice.payment_date).utcOffset("330").format("DD/MM/YYYY")}</span>
                    <Icon style={{position: "absolute", top: 0, right: 10, fontSize: 16}} className="visible-on-hover-item clickable" onClick={() => this.openMarkInvoicePaidModal(invoice)}>edit</Icon>
                </Col>
                <Col xs={1} className="text-center">
                    {!!invoice.total_received_amount && !!(invoice.total_amount - invoice.total_received_amount) && <span className="">&#x20B9;{(invoice.total_amount - invoice.total_received_amount)/100}</span>}
                </Col>
                <Col xs={1}>
                    <span className="d-block">{ invoice.status_text }</span>
                    {invoice.isCreditNoteRaised && <span className="small">CN Raised!</span>}
                </Col>
                <Col xs={1} className={"text-right"}>
                    {!!invoice.invoice_file_id && <Icon className="align-middle clickable" fontSize="small" onClick={() => this.downloadInvoice(invoice.invoice_file_id)}>file_present</Icon>}
                    <UncontrolledDropdown style={{display: "inline-block"}}>
                        <DropdownToggle tag="span">
                            <Button color="dark" outline size="sm" className="px-1 ml-2">
                                <Icon className="align-middle" fontSize="small">more_horiz</Icon>
                            </Button>
                        </DropdownToggle>
                        <DropdownMenu>
                            {!invoice.irn_no && <DropdownItem style={{fontSize: "14px"}} onClick={() => this.generateIRN(invoice)}>Generate IRN Number</DropdownItem>}
                            {invoice.status < 20 && <DropdownItem style={{fontSize: "14px"}} onClick={() => this.sendInvoiceEmail(invoice)}>Resend Email</DropdownItem>}
                            {invoice.status < 20 && <DropdownItem style={{fontSize: "14px"}} onClick={() => this.openMarkInvoicePaidModal(invoice) }>Mark Paid Manually</DropdownItem>}
                            {!!invoice.invoice_file_id && <DropdownItem style={{fontSize: "14px"}} onClick={() => this.downloadInvoice(invoice.invoice_file_id)}>Download Invoice</DropdownItem>}
                            {invoice.status < 20 && <DropdownItem style={{fontSize: "14px"}} onClick={() => this.setState({ isConfirm: () => this.cancelInvoice(invoice), isConfirmMessage: moment().diff(moment(invoice.created_on), "minutes") > 1440 ? "Oops! You cannot cancel the invoice after 24 hours. Do you want to continue with a Credit Note?" : null }) }>Cancel Invoice</DropdownItem>}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
                
            </Row>
            })}
            <TablePagination rowsPerPageOptions={[5, 20, 40, 60, 100, 200]} component="div" count={invoiceList.length} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
               onChangePage={ (e, page) => this.setState({currentUserPage: page}) }
               onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
            />
            </>
            : 
            <Row><Col xs={12} ><p className="text-center text-muted mt-3"> <em>No Invoice generated yet</em></p></Col></Row>
            
        }


        <Drawer PaperProps={{style: {maxWidth: 700, width: "100%"}}} style={{position: "relative"}} anchor={"right"} open={!!this.state.isCreateInvoice} onClose={() => this.setState({ isCreateInvoice: false })}>
            {
                !!this.state.isCreateInvoice && <InvoiceCreate {...this.state.isCreateInvoice} onClose={this.closeCreateInvoice} />
            }
        </Drawer>

        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />

        <Modal isOpen={!!isOpenMarkInvoicePaid} className="modal-dialog-centered" toggle={()=>this.setState({isOpenMarkInvoicePaid:false})}>
            <ModalBody className="p-0">
                <h4 className="d-flex m-3 justify-content-center">Enter Detail!</h4>
                <form onSubmit={(e)=> { e.preventDefault(); this.markPaidInvoice(isOpenMarkInvoicePaid) } }>
                    <Row noGutters className="">
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",width:"100%"}} className="px-4 pb-4">
                        <TextField
                            required
                            id="outlined-controlled"
                            label="Amount received"
                            fullWidth
                            inputProps={{ pattern: '[0-9]*', inputMode: 'numeric', }}
                            margin="normal"
                            value={isOpenMarkInvoicePaid.total_received_amount}
                            onChange={(event) => { 
                                if (/^\d*$/.test(event.target.value)){
                                    isOpenMarkInvoicePaid.total_received_amount = event.target.value;
                                    this.setState({isOpenMarkInvoicePaid})
                                }
                            }}
                        />
                        <TextField
                            id="outlined-uncontrolled"
                            fullWidth
                            inputProps={{ maxLength: 255 }}
                            margin="normal"
                            label="Message"
                            value={isOpenMarkInvoicePaid.payment_message}
                            onChange={(event) => { 
                                if ((event.target.value || "").length <= 255){
                                    isOpenMarkInvoicePaid.payment_message = event.target.value;
                                    this.setState({ isOpenMarkInvoicePaid })
                                }
                            }}
                        />
                        </div>
                    </Row>
					<Row noGutters>
						<Col className="text-light">
							<Button id="delete_confirm_no" type="button" outline color={"danger"} onClick={()=>{this.setState({isOpenMarkInvoicePaid:false})}} className="w-100 py-3" style={{borderRadius : 0}}><span>Cancel</span></Button>
						</Col>
						<Col className="text-light">
							<Button id="delete_confirm_yes" type="submit" color="success" className="w-100 py-3" style={{borderRadius : 0}} >Submit</Button>
						</Col>
					</Row>
                </form>
            </ModalBody>
        </Modal>
    </div>
    )
  }



    getInvoiceStatus = (invoice) => {
        if(moment().isAfter(moment(invoice.expiry_date)) && invoice.status < 20) return <strong className="text-danger">Overdue!</strong>
        else if(invoice.status === 0) return <span className="text-secondary">Created</span>
        else if(invoice.status === 10) return <span className="text-secondary">Sent</span>
        else if(invoice.status === 20) return <span className="text-success">Paid!</span>
        else if(invoice.status === 30) return <span className="text-danger">Overdue!!</span>
        else if(invoice.status === 40) return <span className="text-warning">Cancelled</span>
        else return ""
    }


}


export default InvoiceHome;

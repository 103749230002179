import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import moment from "moment";
import { Icon } from "@material-ui/core";
import CONSTANTS from "../../../constants";


class UserBadgeHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            badgeHistory : [],
        }
    }

    componentDidMount(){
        this.fetchUserBadgeHistory();
    }

    fetchUserBadgeHistory = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id }
            let resp = await ApiEndpoints.UserProfile.fetchUserBadgeHistory(body)
            let badgeHistory = resp.data.data
            this.setState({ badgeHistory })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

      
    
    render() {
        let { badgeHistory } = this.state;
        return (
            <div className="px-1">
                <Row className="my-3">
                    <Col xs={1}>
                        <Icon fontSize="small" style={{cursor: "pointer"}} className="mr-3 align-middle" onClick={() => this.props.onBack()}>arrow_back</Icon>
                    </Col>
                    <Col><span>Badge History</span></Col>
                </Row>
                {badgeHistory.length ? badgeHistory.map(obj => 
                    <Row style={{fontSize: 14, borderRadius: 12}} className="bg-light border align-items-center py-1 my-2" >
                        <Col xs={1}>
                            <img src={require('../../../assets/images/' + CONSTANTS.badgeConfig[obj.badge_level].iconSvgName)} alt='' style={{height:"20px"}} />
                        </Col>
                        <Col xs={5}>
                            <span className="d-block font-weight-bold" style={{color: CONSTANTS.badgeConfig[obj.badge_level].baseColor}}>{ CONSTANTS.badgeConfig[obj.badge_level].name }</span>
                            <span className="d-block small">{moment(obj.created_on).format("Do MMM'YY") }</span>
                        </Col>
                        <Col xs={6} className="text-right">
                            <span>{moment(obj.start_date).format("Do MMM'YY")} - { moment(obj.end_date).format("Do MMM'YY") }</span>
                        </Col>
                    </Row>
                ) : <em className="m-4 text-secondary d-block">No Badge History Found</em> }
                     
                   
            </div>
           
        );
    }
}
export default UserBadgeHistory;
